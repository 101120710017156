import React, {useMemo} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {
  ActiveLoanAggregateStatus,
  LoanActiveStatusActions,
  LoanPaymentStatusCode,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {NamedColors} from 'src/designSystem/colors'
import {
  DateBubblePaymentDate,
  DateBubblePaymentDateStatus,
} from 'src/designSystem/components/atoms/DateBubble/DateBubble'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import StackedButtons, {
  StackedButtonsAction,
} from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'
import {LoanCalendarTileLoanPaymentFields} from 'src/products/loans/Dashboard/LoanCalendarAndPaymentTile/LoanCalendarAndPaymentTile.types'
import {convertLoanPaymentFieldsToDateBubbleDates} from 'src/products/loans/Dashboard/LoanCalendarAndPaymentTile/LoanCalendarAndPaymentTile.utils'
import UpcomingPaymentsTile from 'src/products/loans/Dashboard/LoanCalendarAndPaymentTile/UpcomingPaymentsTile/UpcomingPaymentsTile'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {LoansAdhocPaymentEvents} from 'src/lib/Analytics/app_events'

export type LoanCalendarAndPaymentTileProps = {
  actions: Pick<ActiveLoanAggregateStatus, 'actions'>['actions']
  amountProcessing: string
  isInDefault?: boolean
  isChargedOff?: boolean
  hasPaymentMethodIssue?: boolean
  loanPaymentDates: LoanCalendarTileLoanPaymentFields[]
  onUpdatePaymentDates: () => void
  onMakeAPayment: () => void
  testID?: string
}

/**
 * A calendar of upcoming payments for a loan with buttons to make payments.
 * @example <LoanCalendarAndPaymentTile loanPaymentDates=[] onUpdatePaymentDates={primaryAction} onMakeAPayment={secondaryAction} />
 */
const LoanCalendarAndPaymentTile: React.FC<LoanCalendarAndPaymentTileProps> = ({
  actions,
  amountProcessing,
  isInDefault,
  isChargedOff,
  hasPaymentMethodIssue,
  loanPaymentDates,
  onUpdatePaymentDates,
  onMakeAPayment,
  testID,
}: LoanCalendarAndPaymentTileProps) => {
  const {t} = useTranslation('DashboardLoanCalendarTile')
  // ensure dates are sorted properly
  const remainingUpcomingPayments: LoanCalendarTileLoanPaymentFields[] = loanPaymentDates.filter(
    (thisPayment: LoanCalendarTileLoanPaymentFields): boolean => {
      return (
        thisPayment.statusCode === LoanPaymentStatusCode.Pending ||
        thisPayment.statusCode === LoanPaymentStatusCode.InProgress
      )
    },
  )
  const remainingUpcomingAndFailedPayments: LoanCalendarTileLoanPaymentFields[] =
    loanPaymentDates.filter((thisPayment: LoanCalendarTileLoanPaymentFields): boolean => {
      return (
        thisPayment.statusCode === LoanPaymentStatusCode.Pending ||
        thisPayment.statusCode === LoanPaymentStatusCode.InProgress ||
        thisPayment.statusCode === LoanPaymentStatusCode.Failed
      )
    })

  const remainingPaymentsInProgress: LoanCalendarTileLoanPaymentFields[] = loanPaymentDates.filter(
    (thisPayment: LoanCalendarTileLoanPaymentFields): boolean => {
      return thisPayment.statusCode === LoanPaymentStatusCode.InProgress
    },
  )
  const areAllRemainingPaymentsInProgress: boolean =
    remainingPaymentsInProgress.length === loanPaymentDates.length
  const nextPaymentAmount: string =
    remainingUpcomingPayments[0]?.amount ?? remainingUpcomingAndFailedPayments[0]?.amount

  const paymentDateBubbles: DateBubblePaymentDate[] = convertLoanPaymentFieldsToDateBubbleDates({
    loanPaymentDates,
    isInDefault,
    isChargedOff,
    hasPaymentMethodIssue,
  })
  // if all payments are in progress we will just show a single in progress bubble
  const paymentDateBubbleAllPaymentsInProgress: DateBubblePaymentDate[] = [
    {
      status: DateBubblePaymentDateStatus.IN_PROGRESS,
      key: 'all-payments-in-progress',
    },
  ]

  const updatePaymentDatesAction: StackedButtonsAction = {
    text: t('UpdatePaymentDates'),
    onPress: onUpdatePaymentDates,
    testID: 'LoanCalendarAndPaymentTile-UpdatePaymentDatesBtn',
    disabled: !actions.actions?.includes(LoanActiveStatusActions.UpdatePaymentDates),
  }

  const onPressMakeAPayment = (): void => {
    TrackAppEvent(
      LoansAdhocPaymentEvents.loans_adhoc_dashboard_make_a_payment_cta,
      AppEvents.Category.LoansAdhocPayment,
    )
    onMakeAPayment()
  }

  const makeAPaymentAction: StackedButtonsAction = {
    onPress: onPressMakeAPayment,
    text: t('MakeAPayment'),
    testID: 'LoanCalendarAndPaymentTile-MakeAPaymentBtn',
    disabled: !actions.actions?.includes(LoanActiveStatusActions.MakePayment),
  }

  const primaryAction: StackedButtonsAction = makeAPaymentAction
  const secondaryAction: StackedButtonsAction = updatePaymentDatesAction

  const subtitle = useMemo((): JSX.Element => {
    if (remainingPaymentsInProgress.length > 0) {
      // "Your payment is processing"
      return (
        <PFText variant="p">
          <Trans
            i18nKey="YourPaymentIsProcessing"
            t={t}
            values={{
              finalPaymentAmount: amountProcessing,
            }}
          >
            <PFText variant={'p'} color={NamedColors.SILVER}>
              Your payment of
            </PFText>
            <PFText variant={'p_semibold'} color={NamedColors.SILVER}>
              ${amountProcessing}
            </PFText>
            <PFText variant={'p'} color={NamedColors.SILVER}>
              is processing
            </PFText>
          </Trans>
        </PFText>
      )
    }
    // "Your next payment is"
    return (
      <PFText variant="p">
        <Trans
          i18nKey="YourNextPaymentIs"
          t={t}
          values={{
            nextPaymentAmount,
          }}
        >
          <PFText variant={'p'} color={NamedColors.SILVER}>
            Your next payment is
          </PFText>
          <PFText variant={'p_semibold'} color={NamedColors.SILVER}>
            ${{nextPaymentAmount}}
          </PFText>
        </Trans>
      </PFText>
    )
  }, [remainingPaymentsInProgress, nextPaymentAmount, amountProcessing, t])

  if (isChargedOff) {
    // when the loan is charged off only display the two action buttons
    secondaryAction.mode = 'primary'
    return (
      <BasicTile padding="medium" testID={testID}>
        <StackedButtons
          primary={primaryAction}
          secondary={[secondaryAction]}
          testID="LoanCalendarAndPaymentTile-ChargedOffBtns"
        />
      </BasicTile>
    )
  }

  return (
    <UpcomingPaymentsTile
      testID={testID}
      title={t('UpcomingPayments')}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      paymentDates={
        areAllRemainingPaymentsInProgress
          ? paymentDateBubbleAllPaymentsInProgress
          : paymentDateBubbles
      }
      subtitle={subtitle}
      hasSubtitleIcon={!areAllRemainingPaymentsInProgress}
    />
  )
}

export {LoanCalendarAndPaymentTile}
