import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'

function trackEditEmail(): void {
  TrackAppEvent(AppEvents.Name.edit_email_address_selected, AppEvents.Category.InitialApplication, {
    from: 'verify',
  })
}

function trackResendCode(): void {
  TrackAppEvent(
    AppEvents.Name.enter_email_verification_code_resend_selected,
    AppEvents.Category.Checkout,
  )
}

function trackSubmitCode(): void {
  TrackAppEvent(AppEvents.Name.enter_email_verification_code_submitted, AppEvents.Category.Checkout)
}

export {trackEditEmail, trackResendCode, trackSubmitCode}
