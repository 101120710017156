import {EnvironmentType} from '@possible/cassandra'
import {APIClientOptions} from '@possible/generated/src/api/lib/APIClient'

import {MinimumBinaryVersion} from 'src/lib/MinimumBinaryVersion'

import {
  API_STATE_CHANGE_EVENT,
  CLEAR_USER_ACTION,
  MAINTENANCE_COMPLETED_V1,
  MAINTENANCE_COMPLETED_V2,
  MAINTENANCE_FIREBASE,
  MAINTENANCE_IN_PROGRESS_V1,
  MAINTENANCE_IN_PROGRESS_V2,
  MINIMUM_BINARY_VERSION,
  NETWORK_CONNECTION_FAILURE,
  NETWORK_CONNECTION_SUCCESS,
  STORE_USER_ID_TOKEN_ACTION,
  USER_LOGGED_IN_ACTION,
  USER_SESSION_EXPIRED,
} from 'src/api/actions/actionsNames'

export type UserLoginStatesTypes = 'not_logged_in' | 'logged_in'
export const UserLoginStates = {
  not_logged_in: 'not_logged_in' as UserLoginStatesTypes,
  logged_in: 'logged_in' as UserLoginStatesTypes,
}

export type ApiReduxState = {
  options?: APIClientOptions
  dev_mode: boolean
  envOverride?: EnvironmentType
  init: boolean
  user_logged_state: UserLoginStatesTypes
  user_id: string | undefined
  user_token: string | undefined
  sessionExpired: boolean
  instanceId: string | undefined
  badConnection: boolean
  maintenanceV1: boolean
  maintenanceV2: boolean
  maintenanceFirebase: boolean
  initialUserRefresh: boolean
  minimumBinaryVersion?: MinimumBinaryVersion
}

type APIStateChangeActionType = {type: typeof API_STATE_CHANGE_EVENT; state: ApiReduxState}
type ClearUserActionType = {type: typeof CLEAR_USER_ACTION}
type UserLoggedInActionType = {type: typeof USER_LOGGED_IN_ACTION}
type StoreUserIDTokenActionType = {
  type: typeof STORE_USER_ID_TOKEN_ACTION
  user_id: string
  token: string
}
type UserSessionExpiredActionType = {type: typeof USER_SESSION_EXPIRED}
type NetworkConnectionFailureActionType = {type: typeof NETWORK_CONNECTION_FAILURE}
type NetworkConnectionSuccessActionType = {type: typeof NETWORK_CONNECTION_SUCCESS}
type MaintenanceInProgressV1ActionType = {type: typeof MAINTENANCE_IN_PROGRESS_V1}
type MaintenanceInProgressV2ActionType = {type: typeof MAINTENANCE_IN_PROGRESS_V2}
type MaintenanceCompletedV1ActionType = {type: typeof MAINTENANCE_COMPLETED_V1}
type MaintenanceCompletedV2ActionType = {type: typeof MAINTENANCE_COMPLETED_V2}
type MaintenanceFirebaseActionType = {
  type: typeof MAINTENANCE_FIREBASE
  payload: boolean
}
type MinimumBinaryVersionActionType = {
  type: typeof MINIMUM_BINARY_VERSION
  payload: MinimumBinaryVersion
}
export type ApiActionType =
  | APIStateChangeActionType
  | ClearUserActionType
  | UserLoggedInActionType
  | StoreUserIDTokenActionType
  | UserSessionExpiredActionType
  | NetworkConnectionFailureActionType
  | NetworkConnectionSuccessActionType
  | MaintenanceInProgressV1ActionType
  | MaintenanceInProgressV2ActionType
  | MaintenanceCompletedV1ActionType
  | MaintenanceCompletedV2ActionType
  | MaintenanceFirebaseActionType
  | MinimumBinaryVersionActionType
