import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {PushPage} from 'src/navigation/NavHelper'
import {DocumentRow} from 'src/products/card/CardsHistory/DocumentRow'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {useSelector} from 'react-redux'
import {getActiveAccountId} from 'src/lib/card/selectors'

type Props = StackScreenProps<MainStackParamList, 'DocumentsHistory'>
const DocumentsHistory: React.FC<Props> = (props) => {
  const {navigation} = props
  const {t} = useTranslation(['DocumentsHistory', 'Common'])
  const cardAccountId = useSelector(getActiveAccountId)

  const cardItem = {
    id: 'possibleCard',
    title: t('PossibleCard'),
    onPress: () => {
      TrackAppEvent(
        AppEvents.Name.lefthand_drawer_card_statements_and_documents_selected,
        AppEvents.Category.Admin,
      )
      PushPage(navigation, 'CardsHistory')
    },
  }

  const loanItem = {
    id: 'loans',
    title: t('Loans'),
    onPress: () => {
      PushPage(navigation, 'LoanHistory')
    },
  }

  const items = cardAccountId ? [cardItem, loanItem] : [loanItem]

  const rows = items.map((item) => {
    return <DocumentRow name={item.title} key={item.id} onPress={item.onPress} />
  })

  return (
    <Page variant={'generic'} title={t('HistoryStatementsAndDocuments')} smallTopGap={true}>
      <Box gap={'large'} marginTop={'medium'}>
        {rows}
      </Box>
    </Page>
  )
}

export default DocumentsHistory
