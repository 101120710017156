import React, {ReactElement} from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents from 'src/lib/Analytics/app_events'

import {AcceptLoanAndAutoPayAgreementsGQLContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndAutoPayAgreementsBase/AcceptLoanAndAutoPayAgreementsGQLContainer'

import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {AcceptLoanAndAutoPayAgreementsExtendedPlanTemplate} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptLoanAndAutoPayAgreementsExtendedPlan/AcceptLoanAndAutoPayAgreementsExtendedPlanTemplate'

export type AcceptLoanAndAutoPayAgreementsExtendedPlanWorkflowContainerProps =
  WorkflowPreReqFulfillScreenProps

/**
 * Container to use the AcceptLoandAndAutoPayAgreements screen for WA and LA extended installment plan loans within Workflows.
 */
const AcceptLoanAndAutoPayAgreementsExtendedPlanWorkflowContainer: React.FC<
  AcceptLoanAndAutoPayAgreementsExtendedPlanWorkflowContainerProps
> = (props: AcceptLoanAndAutoPayAgreementsExtendedPlanWorkflowContainerProps) => {
  const {onPreReqFulfilled} = props
  const handleOnAcceptAndContinue = async (): Promise<void> => {
    await onPreReqFulfilled()
  }
  usePageViewedAnalytics({
    eventName: AppEvents.Name.accept_agreements_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'Workflows',
      agreements: 'loan_and_autopay_extended_installment_plan',
    },
  })
  return (
    <AcceptLoanAndAutoPayAgreementsGQLContainer
      testID="AcceptLoanAndAutoPayAgreements-ExtendedPlanWorkflowContainer"
      onAcceptAndContinue={handleOnAcceptAndContinue}
      render={(innerProps): ReactElement => (
        <AcceptLoanAndAutoPayAgreementsExtendedPlanTemplate {...innerProps} />
      )}
    />
  )
}

export {AcceptLoanAndAutoPayAgreementsExtendedPlanWorkflowContainer}
