import React from 'react'
import {TouchableOpacity, StyleSheet, View} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {separatorColor} from 'src/designSystem/semanticColors'
import {smallGap} from 'src/designSystem/layout'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import Box from 'src/designSystem/components/atoms/Box/Box'

type Props = {
  name: string
  onPress: () => void
}

export const DocumentRow: React.FC<Props> = (props) => {
  const {name, onPress: handleOnPress} = props
  return (
    <TouchableOpacity onPress={handleOnPress}>
      <View style={styles.row}>
        <Box flex>
          <PFText variant={'p_lg_semibold'}>{name}</PFText>
        </Box>
        <SvgIcon name={'chevronRight'} colorVariant={'info'} size={'small'} />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  row: {
    alignItems: 'center',
    borderColor: separatorColor,
    flex: 1,
    flexDirection: 'row',
    paddingBottom: smallGap,
    borderBottomWidth: 1,
  },
})
