import {useEffect, useRef} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {LoanOfferAvailabilityStatus} from '@possible/cassandra/src/types/types.mobile.generated'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {LoanDashboardEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {openContactUsForm} from 'src/lib/contactUs'
import {logErrorAndShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {EmitRedirectionEvent} from 'src/lib/utils/events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoansDashboardReduxData} from 'src/products/loans/Dashboard/DashboardLoan.types'
import {DashboardLoanAggregateStatusQueryLoanOfferInfo} from 'src/products/loans/Dashboard/DashboardLoan/queries/useDashboardLoanAggregateStatusQuery'
import {
  WhyChargedOffModalProps,
  showWhyChargedOffModal,
} from 'src/products/loans/Dashboard/WhyChargedOffModal/WhyChargedOffModal'
import {showWhyDefaultModal} from 'src/products/loans/Dashboard/WhyDefaultModal/WhyDefaultModal'
import {PfReduxState} from 'src/reducers/types'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {wfError} from 'src/workflows/logging'
import {ClearSelectedOfferAction, SetWorkflowStateAction} from 'src/workflows/slice'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {DashboardLoanUtilsLatestActionableLoanDocument} from 'src/products/loans/Dashboard/DashboardLoanUtils/DashboardLoan.utils.gqls'
import {Transfer} from 'src/lib/loans/reducers/types'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {getLoanTransfers} from 'src/api/actions/loans/loanActions'

export type DashboardNavigation = StackScreenProps<
  MainStackParamList,
  'Dashboard' | 'ProductHub'
>['navigation']

/**
 * Gather all necessary data from Redux for the loans dashboard V2.
 */
export const useLoansDashboardReduxData = (params: {
  dispatch: ReturnType<typeof usePfDispatch>
}): LoansDashboardReduxData => {
  const {dispatch} = params

  const {data: latestLoanData} = useCassandraQuery(DashboardLoanUtilsLatestActionableLoanDocument, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      Log.error(e, 'DashboardLoanUtils - LatestLoan query failed')
    },
  })

  const loan = latestLoanData?.me.loans.latestActionableLoan

  // refactored from PaymentDetailsTile mapStateToProps()
  useEffect(() => {
    // on mount get latest loan data into redux
    void dispatch(UserStateRefresh())
  }, [dispatch])

  const hasRetrievedTransfers = useRef(false)

  useEffect(() => {
    // first time loan changes we will load transfers
    if (loan?.id && !hasRetrievedTransfers.current) {
      hasRetrievedTransfers.current = true
      void getLoanTransfers(loan.id, dispatch)
    }
  }, [loan, dispatch, hasRetrievedTransfers])

  // the loan to use for transfers. if latest_loan is an installment loan with status=pending,
  // show the transfer payments from the original loan
  let transferLoanId: string | null | undefined
  const originalLoan = loan?.originalLoan?.id ? loan?.originalLoan : null

  const isLoanInPendingStatus = loan?.status && loan?.status?.__typename === 'PendingLoanStatus'

  if (isLoanInPendingStatus && loan?.originalLoan?.id) {
    transferLoanId = originalLoan?.id
  } else {
    transferLoanId = loan?.id
  }
  // get transfers (payments) for this loan
  const transfers =
    usePfSelector((state: PfReduxState): Transfer[] | null => {
      if (
        state.lib?.loans?.transfers &&
        transferLoanId &&
        state.lib.loans.transfers[transferLoanId]
      ) {
        return state.lib.loans.transfers[transferLoanId]
      }
      return null
    }) ?? []

  return {
    onUpdateLoanPaymentDatesReduxData: {
      transfers,
      loan: loan ? {id: loan.id} : null,
    },
  }
}

export const onShowDefaultExplanation = (
  params: Pick<WhyChargedOffModalProps, 'onMakeAPayment'>,
): void => {
  TrackAppEvent(LoanDashboardEvents.default_explanation_selected, AppEvents.Category.LoanDashboard)
  showWhyDefaultModal({
    onMakeAPayment: params.onMakeAPayment,
  })
}

export const onShowChargedOffExplanation = (
  params: Pick<WhyChargedOffModalProps, 'onMakeAPayment'>,
): void => {
  TrackAppEvent(
    LoanDashboardEvents.charged_off_explanation_selected,
    AppEvents.Category.LoanDashboard,
  )
  showWhyChargedOffModal({
    onMakeAPayment: params.onMakeAPayment,
  })
}

const SelectLoanOffer = (
  loanOffers: DashboardLoanAggregateStatusQueryLoanOfferInfo[],
): string | null => {
  // get the first available loan offer we see
  for (const offer of loanOffers) {
    if (
      offer.loanOfferAvailabilityStatus === LoanOfferAvailabilityStatus.OfferAvailable &&
      offer.offers.length > 0
    ) {
      return offer.offers[0].id
    }
  }
  return null
}

/**
 * Handle when a user begins to reapply for a new loan AFTER they've already had their first loan.
 */
export const onReapplyForNewLoan = async (params: {
  navigation: DashboardNavigation
  dispatch: ReturnType<typeof usePfDispatch>
  shouldRedirectLoanApplyAndAcceptToWeb: boolean
  reapplyEventArgs?: object
  loanOffers: DashboardLoanAggregateStatusQueryLoanOfferInfo[]
}): Promise<void> => {
  try {
    const {
      navigation,
      dispatch,
      shouldRedirectLoanApplyAndAcceptToWeb,
      reapplyEventArgs = {},
    } = params
    // reapply_for_loan_selected event is specific to re-applying
    TrackAppEvent(
      LoanDashboardEvents.reapply_for_loan_selected,
      AppEvents.Category.LoanDashboard,
      reapplyEventArgs,
    )
    if (shouldRedirectLoanApplyAndAcceptToWeb) {
      EmitRedirectionEvent('workflows')
    } else {
      try {
        const loanOfferId = SelectLoanOffer(params.loanOffers)
        if (loanOfferId) {
          await dispatch(ClearSelectedOfferAction()).unwrap()
          await dispatch(
            SetWorkflowStateAction({
              selectedOffer: {offerId: loanOfferId, metFrontEndPreReqs: []},
            }),
          ).unwrap()
          navigation.push('OfferApplicationWorkflow', {
            offerId: loanOfferId,
            screen: 'Loading',
          })
        } else {
          throw new Error('No loan offers available')
        }
      } catch (e) {
        const error = e instanceof Error ? e : new Error(String(e))
        wfError(error, 'Failed to select loan offer from loan dashboard')
        loanDashboardLogErrorShowException(error, 'Failed to select loan offer from loan dashboard')
      }
    }
  } catch (e) {
    void loanDashboardLogErrorShowException(
      e instanceof Error ? e : new Error(String(e)),
      'DashboardLoan.utils onReapplyForNewLoan() failed to send user to reapply screen',
    )
  }
}

/**
 * Open the contact us form to let users contact us for any reason.
 */
export const onContactUs = (params: {navigation: DashboardNavigation}): void => {
  TrackAppEvent(LoanDashboardEvents.contact_us_selected, AppEvents.Category.LoanDashboard)
  openContactUsForm(params.navigation)
}

/**
 * Send user to the account management screen to relink their bank account.
 */
export const onManagePaymentAccounts = (params: {navigation: DashboardNavigation}): void => {
  const {navigation} = params
  navigation.navigate('AccountManagementV2')
}

/**
 * Let the user update their loan payment dates schedule. If an upgrade is available it will send them
 * to that UX, otherwise they're sent to the scheduling screen.
 */
export const onUpdateLoanPaymentDates = (params: {
  navigation: DashboardNavigation
  multiPaymentUpgradeAvailable: boolean
  onUpdateLoanPaymentDatesReduxData: LoansDashboardReduxData['onUpdateLoanPaymentDatesReduxData']
}): void => {
  // refactored from loanCardUtils onReschedule()
  const {
    onUpdateLoanPaymentDatesReduxData,
    multiPaymentUpgradeAvailable: isMultiPaymentUpgradeAvailable,
    navigation,
  } = params
  const {transfers, loan} = onUpdateLoanPaymentDatesReduxData
  TrackAppEvent(LoanDashboardEvents.update_payment_dates_selected, AppEvents.Category.LoanDashboard)
  TrackAppEvent(
    ManageActiveLoanEvents.reschedule_payments_selected,
    AppEvents.Category.ManageActiveLoan,
  )
  if (!transfers) {
    const err = new Error('Transfer data not available, cannot update loan payment dates')
    void loanDashboardLogErrorShowException(
      err,
      'DashboardLoan.utils, onUpdateLoanPaymentDates(), unable to update loan payment dates, missing transfer data',
    )
    return
  } //without the transfers we can't make a decision
  if (isMultiPaymentUpgradeAvailable) {
    try {
      navigation.push('UpgradeToInstallment', {isFromDashboard: true})
    } catch (e) {
      void loanDashboardLogErrorShowException(
        e instanceof Error ? e : new Error(String(e)),
        'DashboardLoan.utils, onUpdateLoanPaymentDates(), navigation to UpgradeToInstallment failed',
      )
    }
  } else {
    try {
      if (!loan?.id) {
        throw new Error('loanId not available')
      }
      navigation.push('SelectPaymentToReschedule', {loanId: loan?.id})
    } catch (e) {
      void loanDashboardLogErrorShowException(
        e instanceof Error ? e : new Error(String(e)),
        'DashboardLoan.utils, onUpdateLoanPaymentDates(), navigation to SelectPaymentToReschedule failed',
      )
    }
  }
}

/**
 * Send user to the documents history page to view their loan history.
 */
export const onViewLoanHistory = (params: {navigation: DashboardNavigation}): void => {
  TrackAppEvent(LoanDashboardEvents.view_history_selected, AppEvents.Category.LoanDashboard)
  const {navigation} = params
  navigation.navigate('DocumentsHistory')
}

const addLoanDashboardIdentifierToError = (e: Error): Error => {
  const modifiedError = new Error(`${e.message} - Loans Dashboard`)
  modifiedError.stack = e.stack
  return modifiedError
}

/**
 * Log error and show an exception for all dashboard related errors.  Includes standardized log prefix.
 */
export const loanDashboardLogErrorShowException = (e: unknown, context?: string): void => {
  void logErrorAndShowException(
    addLoanDashboardIdentifierToError(e instanceof Error ? e : new Error(String(e))),
    context,
  )
}

/**
 * Log errors related to the dashboard. Includes standardized log prefix.
 */
export const loanDashboardLogError = (e: Error, msg?: string): void => {
  Log.error(addLoanDashboardIdentifierToError(e), msg)
}
