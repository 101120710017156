import moment from 'moment'
import {createSelector} from 'reselect'

import {
  AchPaymentMethod,
  ActiveCardAccountRestrictionCode,
  ActiveCardAccountStatus,
  Address,
  Card,
  CardAccount,
  CardAccountInstallmentPlan,
  CardAccountOfferInfo,
  CardAccountStatement,
  CardAccountStatusCode,
  CardAccountSuspendedRestriction,
  CardAccountSuspensionReasonCode,
  CardCollection,
  CardPaymentMethodType,
  CardStatusCode,
  CardType,
  DebitCardPaymentMethod,
  FeeSchedule,
  InstallmentPayment,
  LinkedAccount,
  LinkedAccountStatusCode,
  Maybe,
  User,
  UserProfile,
} from '@possible/cassandra/src/types/consumer'
import {CardAccountDashboardStatus} from 'src/products/card/types'
import {formatDate, graphqlDateFormat, utcDate} from 'src/lib/utils/date'

import {PfReduxState} from 'src/reducers/types'

export const getUserProfile = (state: PfReduxState): Maybe<UserProfile> =>
  state.cassandra.user.me?.profile ?? null

export const getUserFullName = createSelector(getUserProfile, (userProfile: Maybe<UserProfile>) =>
  [
    userProfile?.name?.firstName,
    userProfile?.name?.middleName,
    userProfile?.name?.lastName,
    userProfile?.name?.suffix,
  ]
    .filter((i) => !!i)
    .join(' '),
)

export const getUserFullHomeAddress = (state: PfReduxState): Maybe<Address> =>
  state.cassandra.user.me?.profile?.home?.address ?? null

export const getUserFullMailingAddress = (state: PfReduxState): Maybe<Address> =>
  state.cassandra.user.me?.profile?.mailing?.address ?? null

export const getUserSSNMask = (state: PfReduxState): Maybe<string> =>
  state.cassandra.user.me?.identification?.ssn?.mask ?? null

export const getCardAccountOfferInfo = (state: PfReduxState): Maybe<CardAccountOfferInfo> => {
  return state.cassandra?.user?.me?.cardAccounts?.offerInfo ?? null
}

export const getActiveAccount = (state: PfReduxState): Maybe<CardAccount> =>
  state.cassandra.user.me?.cardAccounts?.active ?? null

export const getActiveAccountId = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): Maybe<string> => activeAccount?.id ?? null,
)

export const getActiveAccountCards = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): Maybe<CardCollection> => activeAccount?.cards ?? null,
)

export const getActiveAccountCurrentInstallmentPaymentsCards = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): Maybe<InstallmentPayment[]> => {
    return activeAccount?.installmentPlans?.current?.payments ?? null
  },
)

export const getActiveStatement = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): Maybe<CardAccountStatement> =>
    activeAccount?.statements?.active ?? null,
)

export const getUser = (state: PfReduxState): Maybe<User> => state.cassandra.user.me ?? null

export const getCreditLimit = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): string => activeAccount?.creditLimit ?? '0',
)

export const getActiveAccountMonthlyMembershipFee = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): string =>
    activeAccount?.cardAccountType?.monthlyMembershipFees ?? '0',
)

export const getAvailableBalance = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): string => {
    let availableBalance = '0'

    if (
      activeAccount?.status?.__typename === 'ActiveCardAccountStatus' ||
      activeAccount?.status?.__typename === 'DeactivatedCardAccountStatus' ||
      activeAccount?.status?.__typename === 'ExpiredCardAccountStatus'
    ) {
      availableBalance = activeAccount?.status?.balance?.availableBalance ?? '0'
    }
    return availableBalance
  },
)

export const getCurrentBalance = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): string => activeAccount?.balance?.ledgerBalance ?? '0',
)

export const getLedgerSpentAfterPayments = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): number => {
    switch (activeAccount?.status?.__typename) {
      case 'ActiveCardAccountStatus':
      case 'DeactivatedCardAccountStatus':
      case 'ExpiredCardAccountStatus':
        return Number(activeAccount?.status?.balance?.ledgerSpentAfterPayments ?? '0')
      default:
        return 0
    }
  },
)

const createGetCardSelector = (cardType: CardType, cardStatus?: CardStatusCode) => {
  return createSelector<PfReduxState, Maybe<CardAccount>, Maybe<Card>>(
    getActiveAccount,
    (activeAccount) => {
      const cards = activeAccount?.cards?.issued ?? []
      const cardFound = cards.find(
        (card) => (!cardStatus || card.status?.code === cardStatus) && card?.type === cardType,
      )
      return cardFound ?? null
    },
  )
}

export const getPendingPhysicalCard = createGetCardSelector(
  CardType.PhysicalCard,
  CardStatusCode.Pending,
)

/**
 * Return the physical card if it is active, otherwise return virtual card
 */
export const getUsableVirtualOrPhysicalCard = createSelector(
  createGetCardSelector(CardType.PhysicalCard, CardStatusCode.Active),
  createGetCardSelector(CardType.PhysicalVirtualCard),
  (activePhysicalCard?: Maybe<Card>, virtualCard?: Maybe<Card>) => {
    return activePhysicalCard || virtualCard
  },
)

const getPaymentMethodTypeFactory = (paymentMethodType: string) => {
  return createSelector<PfReduxState, Maybe<CardAccount>, CardPaymentMethodType[]>(
    getActiveAccount,
    (activeAccount) => {
      const allAccounts = activeAccount?.paymentMethods?.all ?? []
      return allAccounts?.filter((account) => account?.__typename === paymentMethodType)
    },
  )
}

export const getAchPaymentMethods = getPaymentMethodTypeFactory('AchPaymentMethod') as (
  state: PfReduxState,
) => AchPaymentMethod[]

export const getDebitPaymentMethods = getPaymentMethodTypeFactory('DebitCardPaymentMethod') as (
  state: PfReduxState,
) => DebitCardPaymentMethod[]

const linkedAccountStatuses: (LinkedAccountStatusCode | undefined | null)[] = [
  LinkedAccountStatusCode.LinkedInUse,
]
export const getAllPlaidLinkedBankAccounts = (state: PfReduxState): LinkedAccount[] => {
  const allAccounts = state.cassandra.user.me?.bankAccounts?.all ?? []
  return allAccounts.filter((account) => linkedAccountStatuses.includes(account?.status))
}

export const getActiveAccountStatusCode = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): CardAccountStatusCode | undefined =>
    activeAccount?.status?.code,
)

export const doesUserHaveActiveCard = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): boolean =>
    !!activeAccount?.id && activeAccount?.status?.code === CardAccountStatusCode.Active,
)

// Does the user have a card or applying for a card?
export const doesUserHaveCard = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): boolean =>
    !!activeAccount?.id &&
    [
      CardAccountStatusCode.Active,
      CardAccountStatusCode.Approved,
      CardAccountStatusCode.Pending,
    ].includes(activeAccount?.status?.code),
)

export const getShouldEnrollInCards = (state: PfReduxState): boolean =>
  state.lib.card?.shouldEnrollInCards

export const getPreviousCardAccountId = (state: PfReduxState): string | undefined =>
  state.lib.card?.previousCardAccountId

export const doesUserHavePendingCard = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): boolean =>
    !!activeAccount?.id && activeAccount?.status?.code === CardAccountStatusCode.Pending,
)

export const isUserApprovedForCard = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): boolean =>
    !!activeAccount?.id && activeAccount?.status?.code === CardAccountStatusCode.Approved,
)

export const isAccountLocked = (activeAccount: Maybe<CardAccount>): boolean => {
  if (activeAccount?.status?.__typename === 'ActiveCardAccountStatus') {
    return activeAccount?.status?.restriction?.code === ActiveCardAccountRestrictionCode.Locked
  } else {
    return false
  }
}

export const getCardActiveAccountIsLocked = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): boolean => {
    return isAccountLocked(activeAccount)
  },
)

/**
 * Get the status to use for the dashboard
 * The current card must be active
 */
export const getCardAccountDashboardStatus = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): CardAccountDashboardStatus => {
    const accountStatus = activeAccount?.status as ActiveCardAccountStatus
    const statusReason = accountStatus?.restriction as CardAccountSuspendedRestriction

    switch (statusReason?.reason) {
      case CardAccountSuspensionReasonCode.PayLaterAgreement:
        return CardAccountDashboardStatus.SuspendedPayOverTime
      case CardAccountSuspensionReasonCode.Fraud:
        return CardAccountDashboardStatus.SuspendedFraud
      default:
        return CardAccountDashboardStatus.Active
    }
  },
)

export const getCardActiveAccountCurrentInstallmentPlan = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): Maybe<CardAccountInstallmentPlan> | undefined => {
    return activeAccount?.installmentPlans?.current
  },
)

/**
 * Get the next date a payment is due
 * If the user is enrolled in Pay Over Time, return the date of the next installment
 * If the user's account is in default, return the time it went into default
 * If the user is enrolled in autopay, return the next autopay date
 * Otherwise return the paymentDueDate of the current statement
 * If that is not available, return undefined
 */
export const getNextPaymentDate = createSelector(
  getActiveAccount,
  getCardAccountDashboardStatus,
  getActiveAccountCurrentInstallmentPaymentsCards,
  (
    activeAccount: Maybe<CardAccount>,
    accountStatus: CardAccountDashboardStatus,
    activeAccountCurrentInstallmentPaymentsCards: Maybe<InstallmentPayment[]>,
  ): string | undefined => {
    if (!activeAccount) {
      return undefined
    }

    if (accountStatus === CardAccountDashboardStatus.SuspendedPayOverTime) {
      const futureInstallment = activeAccountCurrentInstallmentPaymentsCards?.find(
        (payment) => payment?.executeAt && moment(payment?.executeAt, utcDate).isAfter(moment()),
      )
      if (!futureInstallment?.executeAt) {
        return undefined
      }
      return formatDate(futureInstallment?.executeAt, graphqlDateFormat, utcDate) ?? undefined
    } else if (activeAccount?.autopayEnabled) {
      return (
        formatDate(
          activeAccount?.payments?.schedule?.[0]?.paymentDate,
          graphqlDateFormat,
          utcDate,
        ) ?? undefined
      )
    } else {
      const date = activeAccount?.payments?.schedule?.[0]?.paymentDate
      return date ? formatDate(date, graphqlDateFormat, utcDate) : undefined
    }
  },
)

export const getPotentialFeeSchedule = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): Maybe<FeeSchedule>[] | null => {
    return activeAccount?.fees?.potential ?? null
  },
)
