import {useEffect, useId} from 'react'

import {realtimeDatabaseAdapter} from 'src/lib/RealtimeDatabase/RealtimeDbAdapter/RealtimeDbAdapter'
import {
  RealtimeDbProperties,
  RealtimeDbProperty,
  OnValueChangeCallback,
} from 'src/lib/RealtimeDatabase/RealtimeDb.types'

/**
 * Manages subscriptions to realtime database properties. Subscribes to a property's
 * updates and calls any listener callbacks that are subscribed to that property when
 * the value changes.
 */
class RealtimeDbSubscriptionManager {
  /**
   * Maintains a list of all subscription handlers for each property. This allows
   * multiple components to subscribe to the same property simultaneously if desired
   * while sharing one .on('value') listener.
   */
  private subscriptions: {
    [key in RealtimeDbProperty]: {
      // this seems unnecessary since the key is of the same type. it's to make typescript happy when iterating over the object
      property: RealtimeDbProperty
      // persist latest value so that we can call the onValueChange listener immediately
      // if a new listener is added after the value has already been received
      latestValue: unknown
      // each subscription gets a uniqueID so that it can be unsubscribed later
      listeners: {
        [subscriptionId: number]: OnValueChangeCallback
      }
    }
  } = {
    [RealtimeDbProperties.MaintenanceMode]: {
      property: RealtimeDbProperties.MaintenanceMode,
      latestValue: undefined,
      listeners: {},
    },
    [RealtimeDbProperties.MinimumBinaryVersion]: {
      property: RealtimeDbProperties.MinimumBinaryVersion,
      latestValue: undefined,
      listeners: {},
    },
  }

  /**
   * Call any listener callbacks that are subscribed to a property with a new value for the property.
   */
  callSubscriptionListeners = (dbProperty: RealtimeDbProperty, dbValue: unknown): void => {
    Object.values(this.subscriptions[dbProperty].listeners).forEach((thisListener) =>
      thisListener(dbValue),
    )
  }

  /**
   * Set up a new subscription to a realtime db property.
   * @param dbProperty
   */
  setUpSubscription = (dbProperty: RealtimeDbProperty): void => {
    realtimeDatabaseAdapter.subscribe(dbProperty, (dbProperty, value) => {
      // cache latest value
      this.subscriptions[dbProperty].latestValue = value
      // call all the onValueChange listeners for this property
      this.callSubscriptionListeners(dbProperty, value)
    })
  }

  /**
   * Initialize the realtime database adapter.
   */

  init = async (): Promise<void> => {
    // note: this is temporary until binary version uses the new subscribe/unsubscribe pattern via useSubscribeToRealtimeDbProperty()
    await realtimeDatabaseAdapter.init()

    Object.values(this.subscriptions).forEach((thisSubscription) => {
      // if there are listeners already subscribed to a property (probably because we
      // called unsubscribeWhenAppIsInBackground when the app was in the background),
      // then we should re-subscribe to that property now
      if (Object.values(thisSubscription.listeners).length > 0) {
        this.setUpSubscription(thisSubscription.property)
      }
    })
  }

  /**
   * Hook to allow subscribing to a realtime database property and receive updates
   * when the value changes. This automatically unsubscribes when the calling component
   * is unmounted if no other components are listening to the same property.
   *
   * You probably want to wrap `onValueChange` in a `useCallback`.
   */
  useSubscribeToRealtimeDbProperty = (
    property: RealtimeDbProperty,
    onValueChange: OnValueChangeCallback,
  ): void => {
    // store this new onValueChange listener with a uniqueId to reference later
    const subscriptionId = useId()

    useEffect(() => {
      // check if we need to set up a subscription or not. we only subscribe once per property
      const isAlreadySubscribed = Object.values(this.subscriptions[property].listeners).length > 0
      this.subscriptions[property].listeners[subscriptionId] = onValueChange

      // we only set up one subscription per property. after that the subscription will call any
      // registered onValueChange listeners in subscriptions[property].listeners when the property changes.
      // this is so that we can share one subscription for any number of components listening for this property
      if (!isAlreadySubscribed) {
        this.setUpSubscription(property)
      } else {
        // if we're already subscribed, then we should call the onValueChange listener immediately
        // so that the component can update its state with the current value until a new
        // value arrives from the subscription
        onValueChange(this.subscriptions[property].latestValue)
      }

      return (): void => {
        // remove this subscription onValueChange handler when the component is unmounted
        delete this.subscriptions[property].listeners[subscriptionId]
        const areThereAnyRemainingListeners =
          Object.values(this.subscriptions[property].listeners).length > 0
        if (!areThereAnyRemainingListeners) {
          // if there are no more onValueChange listeners for this property, then we can unsubscribe entirely
          realtimeDatabaseAdapter.unsubscribe(property)
        }
      }
    }, [onValueChange, property, subscriptionId])
  }

  /**
   * When the app is in the background we unsubscribe from all properties that we are currently subscribed to
   * so that we don't pay for receiving updates when user is not in the app.
   */
  unsubscribeWhenAppIsInBackground = (): void => {
    // note: this is temporary until binary version uses the new subscribe/unsubscribe pattern via useSubscribeToRealtimeDbProperty()
    realtimeDatabaseAdapter.unsubscribeBinaryVersion()

    Object.values(this.subscriptions).forEach((thisSubscription) => {
      // if we have any listeners that are currently subscribed we will unsubscribe
      if (Object.values(thisSubscription.listeners).length > 0) {
        realtimeDatabaseAdapter.unsubscribe(thisSubscription.property)
      }
    })
  }
}

// export a singleton instance
export const subscriptionManager = new RealtimeDbSubscriptionManager()
