import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {AddNewAccountToPossibleTemplate} from 'src/products/MCU/AccountManagementV2/AddNewAccount/AddNewAccountToPossibleTemplate'
import {usePfSelector} from 'src/store/utils'
import {getUserEnvSelector} from '@possible/cassandra'
import {aggregatorsId} from 'src/lib/user/userEnvConsts'
import {convertAggregatorStringToType} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {AccountManagementEvents} from 'src/lib/Analytics/app_events'

type Props = StackScreenProps<MainStackParamList, 'AddNewAccountToPossible'>

const AddNewAccountToPossible: FC<Props> = (props: Props) => {
  const {navigation, route} = props
  const {onAddNewLinkedAccount} = route.params
  const env = usePfSelector(getUserEnvSelector)

  const isYodleeAggregator =
    convertAggregatorStringToType(env?.bankingAggregatorId) === aggregatorsId.yodlee

  const handleOnAddNewLinkedAccount = (): void => {
    TrackAppEvent(
      AccountManagementEvents.add_new_payment_account,
      AppEvents.Category.AccountManagement,
      {
        type: isYodleeAggregator ? 'link_with_yodlee' : 'link_with_plaid',
      },
    )
    onAddNewLinkedAccount()
  }

  const handleOnAddNewDebitCard = (): void => {
    TrackAppEvent(
      AccountManagementEvents.add_new_payment_account,
      AppEvents.Category.AccountManagement,
      {
        type: 'debit_card',
      },
    )
    navigation.navigate('CollectDebitCardNumberForAdhocPayment', {
      onSuccessRouteDestination: 'AccountManagementV2',
    })
  }
  const handleOnAddAccountRoutingNumber = (): void => {
    TrackAppEvent(
      AccountManagementEvents.add_new_payment_account,
      AppEvents.Category.AccountManagement,
      {
        type: 'routing_account',
      },
    )
    navigation.navigate('BankAddRoutingAndAccount', {
      onSuccessRouteDestination: 'AccountManagementV2',
    })
  }
  return (
    <AddNewAccountToPossibleTemplate
      onAddNewLinkedAccount={handleOnAddNewLinkedAccount}
      onAddAccountRoutingNumber={handleOnAddAccountRoutingNumber}
      onAddNewDebitCard={handleOnAddNewDebitCard}
      useYodleeAggregator={isYodleeAggregator}
    />
  )
}

export {AddNewAccountToPossible}
