//// ACTION TYPE NAMES
export const CLEAR_USER_ACTION = 'CLEAR_USER_ACTION'
export const STORE_USER_ID_TOKEN_ACTION = 'STORE_USER_ID_TOKEN_ACTION'
export const USER_LOGGED_IN_ACTION = 'USER_LOGGED_IN_ACTION'
export const TOP_SCENE_KEY = 'TOP_SCENE_KEY'
export const USER_SESSION_EXPIRED = 'USER_SESSION_EXPIRED'
export const NAVIGATOR_READY = 'NAVIGATOR_READY'
export const NETWORK_CONNECTION_FAILURE = 'NETWORK_CONNECTION_FAILURE'
export const NETWORK_CONNECTION_SUCCESS = 'NETWORK_CONNECTION_SUCCESS'
export const MAINTENANCE_IN_PROGRESS_V1 = 'MAINTENANCE_IN_PROGRESS_V1'
export const MAINTENANCE_COMPLETED_V1 = 'MAINTENANCE_COMPLETED_V1'
export const MAINTENANCE_IN_PROGRESS_V2 = 'MAINTENANCE_IN_PROGRESS_V2'
export const MAINTENANCE_COMPLETED_V2 = 'MAINTENANCE_COMPLETED_V2'
export const MAINTENANCE_FIREBASE = 'MAINTENANCE_FIREBASE'
export const MINIMUM_BINARY_VERSION = 'MINIMUM_BINARY_VERSION'
export const API_STATE_CHANGE_EVENT = 'api_state_change'
