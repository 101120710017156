import {
  MAINTENANCE_COMPLETED_V1,
  MAINTENANCE_COMPLETED_V2,
  MAINTENANCE_IN_PROGRESS_V1,
  MAINTENANCE_IN_PROGRESS_V2,
} from 'src/api/actions/actionsNames'
import {maintenanceOnApi} from 'src/api/selectors/selectors'
import {ApiVersion} from 'src/products/general/MaintenanceInProgress/MaintenanceInProgress.types'
import {PfReduxState} from 'src/reducers/types'
import {usePfDispatch} from 'src/store/utils'

export function MaintenanceInProgress(prefix: ApiVersion) {
  return (dispatch: ReturnType<typeof usePfDispatch>, getState: () => PfReduxState): void => {
    const state = getState()
    if (prefix === ApiVersion.V1 && !maintenanceOnApi(state, prefix)) {
      dispatch({type: MAINTENANCE_IN_PROGRESS_V1})
    }
    if (prefix === ApiVersion.V2 && !maintenanceOnApi(state, prefix)) {
      dispatch({type: MAINTENANCE_IN_PROGRESS_V2})
    }
  }
}

export function MaintenanceDone(prefix: ApiVersion) {
  return (dispatch: ReturnType<typeof usePfDispatch>, getState: () => PfReduxState): void => {
    const state = getState()
    if (prefix === ApiVersion.V1 && maintenanceOnApi(state, prefix)) {
      dispatch({type: MAINTENANCE_COMPLETED_V1})
    }
    if (prefix === ApiVersion.V2 && maintenanceOnApi(state, prefix)) {
      dispatch({type: MAINTENANCE_COMPLETED_V2})
    }
  }
}
