import React from 'react'
import {useSelector} from 'react-redux'

import NoTransactions from 'src/assets/illustrations/NoTransactions.svg'
import {getActiveAccount} from 'src/lib/card/selectors'
import {ItemType} from 'src/products/card/Dashboard/TransactionsAndPaymentsList/utils'
import {useAllTransactionItems} from 'src/products/card/Dashboard/TransactionsAndPaymentsList/useAllTransactionItems'
import EmptyState from 'src/designSystem/components/molecules/EmptyState/EmptyState'
import GenericList from 'src/designSystem/components/molecules/GenericList/GenericList'
import {ListItemBase} from 'src/products/card/Dashboard/ListItemBase'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'

type TransactionsAndPaymentsListProps = {
  maxItems?: number
}

export const getContentComponent = (item: ItemType): JSX.Element => {
  const optionalProps = {}
  if (item.status) {
    optionalProps['pillProps'] = {
      text: item.status,
      color: item.pillColor,
      fontColor: item.pillTextColor,
    }
  }

  return (
    <ListItemBase
      key={item.title}
      title={item.title}
      titleColor={item.titleColor}
      date={item.date}
      serviceChargeDescription={item.description}
      descriptionColor={item.descriptionColor}
      amountColor={item.amountColor}
      amount={item.amount}
      amountPrefix={item.amountPrefix}
      icon={item.icon}
      {...optionalProps}
    />
  )
}

const TransactionsAndPaymentsList: React.FC<TransactionsAndPaymentsListProps> = (props) => {
  const {maxItems} = props

  const cardAccount = useSelector(getActiveAccount)
  const allTransactionItems = useAllTransactionItems()

  if (!cardAccount) {
    return <Spinner />
  }

  if (!allTransactionItems?.length) {
    return <EmptyState image={NoTransactions} />
  }

  return (
    <GenericList
      items={allTransactionItems
        ?.slice(0, maxItems ?? Number.POSITIVE_INFINITY)
        ?.map((item: ItemType) => getContentComponent(item))}
      boxGap="small"
    />
  )
}

export {TransactionsAndPaymentsList}
