import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {Trans, useTranslation} from 'react-i18next'

import {completeUra} from '@possible/cassandra'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ShowException} from 'src/lib/errors'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {UploadDocuments} from 'src/products/general/UserRequestedActions/UploadDocuments/UploadDocuments'
import {UserDocumentSubType} from '@possible/cassandra/src/types/types.mobile.generated'
import AppEvents from 'src/lib/Analytics/app_events'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Log from 'src/lib/loggingUtil'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

export type ProofOfIncomeProps = StackScreenProps<MainStackParamList, 'ProofOfIncome'>

export const ProofOfIncome: React.FC<ProofOfIncomeProps> = (props) => {
  const {navigation, route} = props
  const uraIdToComplete = route?.params?.uraIdToComplete
  const {t} = useTranslation(['UserRequestedAction'])

  async function handleOnContinue(): Promise<void> {
    try {
      await completeUra(uraIdToComplete)
      TrackAppEvent(
        AppEvents.Name.proof_of_income_URA_completed,
        AppEvents.Category.InitialApplication,
      )
      // We must call this twice. pop(2) doesn't work because they're in different stacks
      navigation.goBack() // Remove this screen
      navigation.goBack() // Remove URA lightbox
    } catch (e) {
      Log.error(e, `ProofOfIncome failed to complete URA with id: ${uraIdToComplete}`)
      ShowException(e)
    }
  }

  return (
    <UploadDocuments
      onContinue={handleOnContinue}
      documentSubType={UserDocumentSubType.ProofOfIncome}
      title={t('VerifyYourIncome')}
      body={
        <Box gap={'medium'} marginBottom={'little'}>
          <PFText variant="p">
            <Trans t={t} i18nKey="UploadPayStubs">
              Upload pay stubs showing your name and your personal income. Include
              <PFText variant="p_semibold">30 days worth of income.</PFText>
            </Trans>
          </PFText>
          <PFText variant="p">{t('FeelFreeToUploadMultipleFiles')}</PFText>
        </Box>
      }
    />
  )
}
