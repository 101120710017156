import React, {useEffect, useState} from 'react'
import {DimensionValue, View} from 'react-native'
import {SafeAreaProvider} from 'react-native-safe-area-context'
import {Provider} from 'react-redux'
import {NavigationContainer, useTheme} from '@react-navigation/native'
import {useReduxDevToolsExtension} from '@react-navigation/devtools'
import {GestureHandlerRootView} from 'react-native-gesture-handler'

import {NotificationContainer} from 'src/products/general/NotificationContainer/NotificationContainer'
import {OverlayProvider} from 'src/designSystem/components/organisms/Overlay/OverlayProvider'
import 'src/lib/localization/i18n'
import {getStorybookPref, StorybookState} from 'src/lib/storybookPref'
import {isDeviceWeb} from 'src/lib/utils/platform'
import AppInit from 'src/nav/AppInit'
import {getPfStore} from 'src/store'
import RootStack from 'src/nav/Stacks/AppStack'
import {AppStateManager} from 'src/nav/AppStateManager'
import {UseCassandra} from 'src/cassandra/src/env/UseCassandra'

import {rootNavigationRef} from 'src/nav/RootStackNavigation'
import {rootStackNavContainerOnReady} from 'src/nav/RootStackPlatform'
import {NavContextProvider} from 'src/nav/NavContext'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import Datadog from 'src/lib/Analytics/Datadog'
import BinaryUpdateNotifier from 'src/products/loans/BinaryUpdateNotifier'
import MaintenanceInProgressNotifier from 'src/products/general/MaintenanceInProgress/MaintenanceInProgressNotifier'
import AppStatusBar from 'src/nav/AppStatusBar'
import {initializeAmplitudeExperimentation} from 'src/lib/experimentation/amplitude/amplitude'
import {ProcessAppLaunchArgs} from 'src/lib/LaunchArgs/LaunchArgs'
const StorybookAppLoaderInner = React.lazy(() => import('src/storybook/StorybookApp'))

const maxHeight = 940 // Make sure that value matches the value set into index.hml for #root
const getHeight = (): number => {
  return Math.min(window.innerHeight, maxHeight)
}

const AppPossible: React.FC = () => {
  const [storybookMode, setStorybookMode] = useState<StorybookState>(StorybookState.INIT)
  const [height, setHeight] = useState(getHeight())
  const [initComplete, setInitComplete] = useState(false)

  useReduxDevToolsExtension(rootNavigationRef)

  const theme = useTheme()
  theme.colors.background = 'transparent'

  const handleHeightChange = () => {
    setHeight(getHeight())
  }

  const loadStorybookState = async () => {
    const sbMode = (await getStorybookPref())
      ? StorybookState.STORYBOOK
      : StorybookState.NO_STORYBOOK
    setStorybookMode(sbMode)
  }

  useEffect(() => {
    const init = async () => {
      ProcessAppLaunchArgs()
      await AppInit()
      await loadStorybookState()
      await initializeAmplitudeExperimentation(undefined)
      setInitComplete(true)
    }
    init()
  }, [])

  useEffect(() => {
    if (isDeviceWeb() && rootNavigationRef.current !== null) {
      rootNavigationRef.current?.addListener('state', () => {
        Datadog.manuallyTrackViews(rootNavigationRef.current?.getCurrentRoute()?.name ?? 'Unknown')
      })
    }

    return () => {
      if (isDeviceWeb()) {
        rootNavigationRef.current?.removeListener('state', () => /* no-nop */ undefined)
      }
    }
  }, [rootNavigationRef])

  useEffect(() => {
    if (isDeviceWeb()) {
      window.addEventListener('resize', handleHeightChange)
    }

    return () => {
      if (isDeviceWeb()) {
        window.removeEventListener('resize', handleHeightChange)
      }
    }
  }, [])

  if (!initComplete) {
    return <Loading type="loader0" size="large" />
  }

  let heightStr: DimensionValue = '100%'
  if (isDeviceWeb()) {
    // @ts-expect-error We need to use a css value here
    heightStr = `${height}px`
  }

  switch (storybookMode) {
    case StorybookState.NO_STORYBOOK:
      return (
        <Provider store={getPfStore()}>
          <SafeAreaProvider>
            <GestureHandlerRootView style={{flex: 1}}>
              <AppStatusBar />
              <AppStateManager />
              <OverlayProvider>
                <View
                  id={'outerFrame'}
                  style={{
                    width: '100%',
                    height: heightStr,
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <View
                    id={'innerFrame'}
                    style={{
                      height: '100%',
                      width: '100%',
                      justifyContent: 'center',
                      // @ts-expect-error
                      boxShadow: '0px 4px 8px -2px rgba(5,16,41, .2)',
                    }}
                  >
                    <BinaryUpdateNotifier>
                      <MaintenanceInProgressNotifier>
                        <NavigationContainer
                          ref={rootNavigationRef}
                          onReady={() => {
                            rootStackNavContainerOnReady(rootNavigationRef)
                            Datadog.trackViews(rootNavigationRef.current)
                          }}
                          theme={theme}
                        >
                          <UseCassandra>
                            <NavContextProvider>
                              <RootStack />
                            </NavContextProvider>
                          </UseCassandra>
                        </NavigationContainer>
                      </MaintenanceInProgressNotifier>
                    </BinaryUpdateNotifier>
                  </View>
                </View>
                <NotificationContainer />
              </OverlayProvider>
            </GestureHandlerRootView>
          </SafeAreaProvider>
        </Provider>
      )
    case StorybookState.STORYBOOK:
      return <StorybookAppLoaderInner />

    default:
      return null
  }
}

export default AppPossible
