import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {useSelector} from 'react-redux'

import {CardAutopayOverviewNavigationContainer} from 'src/products/card/Activation/CardAutopayOverview/CardAutopayOverviewNavigationContainer'
import {PaymentFlow} from 'src/products/card/PaymentMethods/types'
import {getPaymentMethodAccount} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {getDefaultPaymentMethod} from 'src/products/card/PaymentMethods/selectors'

type Props = StackScreenProps<MainStackParamList, 'HowDoesAutopayWork'> & {
  flow?: PaymentFlow
}

export const HowDoesAutopayWork: React.FC<Props> = (props) => {
  const {navigation, flow} = props

  const defaultPaymentMethod = useSelector(getDefaultPaymentMethod)

  const pushNextPage = (): void => {
    if (!defaultPaymentMethod) {
      PushPage(navigation, 'AddBankLink', {flow: PaymentFlow.SwitchToAutopay})
      return
    }
    if (defaultPaymentMethod.__typename === 'LinkedAccount') {
      PushPage(navigation, 'CardVerifyBankDetails', {
        bankIdToFilter: getPaymentMethodAccount(defaultPaymentMethod)?.id,
        flow: PaymentFlow.SwitchToAutopay,
      })
    } else {
      if (flow === PaymentFlow.PayOverTime) {
        PushPage(navigation, 'CardPayOverTimeDetails')
      } else {
        PushPage(navigation, 'CardReviewAutopay')
      }
    }
  }

  return (
    <CardAutopayOverviewNavigationContainer
      onComplete={async (): Promise<void> => {
        pushNextPage()
        return Promise.resolve()
      }}
    />
  )
}
