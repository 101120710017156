import React from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {CompositeScreenProps} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'
import moment from 'moment'

import Page from 'src/designSystem/components/organisms/Page/Page'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {formatDate, friendlyDateWithDOW} from 'src/lib/utils/date'
import {useUser} from 'src/cassandra'
import {getPaymentMethodAccount} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {PaymentMethodLink} from 'src/products/card/PaymentMethods/PaymentMethodLink/PaymentMethodLink'
import {
  hasPaymentInstrument,
  getPayNowPaymentMethod,
} from 'src/products/card/PaymentMethods/selectors'
import {PaymentFlow} from 'src/products/card/PaymentMethods/types'
import {PfReduxState} from 'src/reducers/types'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {CardAdhocPaymentStack} from 'src/products/card/AdhocPayment/CardAdhocPaymentStack'
import {UnlinkedBankPaymentMethodLink} from 'src/products/card/PaymentMethods/PaymentMethodLink/UnlinkedBankPaymentMethodLink'
import {useCardAdhocPayment} from 'src/products/card/AdhocPayment/useCardAdhocPayment/useCardAdhocPayment'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PFInfoCapsule} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'
import {getCardAccountPOTStatus} from 'src/products/card/Dashboard/CardDashboardUtils'
import {CardAccountPOTStatus} from 'src/products/card/types'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {CardActionListItem} from 'src/products/card/components/molecules/CardActionListItem'
import {useCardAdhocPaymentInput} from 'src/products/card/AdhocPayment/useCardAdhocPaymentInput'
import {useCardAdhocPaymentAnalytics} from 'src/products/card/AdhocPayment/useCardAdhocPaymentAnalytics'

type CardAdhocPaymentReviewProps = CompositeScreenProps<
  StackScreenProps<CardAdhocPaymentStack, 'CardAdhocPaymentReview'>,
  StackScreenProps<MainStackParamList>
>

const CardAdhocPaymentReview = ({navigation}: CardAdhocPaymentReviewProps): JSX.Element => {
  const {eventArgs, isLoading: isLoadingEventArgs} = useCardAdhocPaymentAnalytics()
  usePageViewedAnalytics({
    eventName: CardEvents.card_adhoc_payment_review_page_viewed,
    eventCategory: AppEvents.Category.Card,
    eventArgs,
    isLoading: isLoadingEventArgs,
  })
  const selectedPaymentMethod = useSelector(getPayNowPaymentMethod)
  const {t} = useTranslation('CardAdhocPayment')
  const {currentBalance, data, executePayment, isLoading, paymentState, userSelectedButtonId} =
    useCardAdhocPayment()
  const {selectedButton} = useCardAdhocPaymentInput()
  const doesPrimaryPaymentMethodHavePaymentInstrument = useSelector((state: PfReduxState) =>
    hasPaymentInstrument(state, selectedPaymentMethod?.id),
  )

  useUser(false)

  const activeAccountStatus = data?.me.cardAccounts.active
  const accountPOTStatus = getCardAccountPOTStatus(activeAccountStatus)

  const getPrimaryButtonText = (): string => {
    let buttonText = t('PayNow')

    if (!selectedPaymentMethod) {
      buttonText = t('RelinkAccount')
    } else if (!doesPrimaryPaymentMethodHavePaymentInstrument) {
      buttonText = t('CompletePaymentSetup')
    }

    return buttonText
  }

  const handleOnPressRelinkAccount = (): void => {
    TrackAppEvent(
      CardEvents.card_adhoc_payment_relink_account_clicked,
      AppEvents.Category.Card,
      eventArgs,
    )

    // If the user has no payment instrument and no linked bank accounts,
    // require them to link a bank account
    if (!selectedPaymentMethod) {
      return navigation.navigate('AddBankLink', {flow: PaymentFlow.PayNow})
    }

    // If the primary payment method doesn't have a payment instrument yet (is linked account)
    // make the user to enter the account + routing number to create the payment instrument
    // or if backend flags from debit/ach are set to false
    if (!doesPrimaryPaymentMethodHavePaymentInstrument) {
      return navigation.navigate('CardVerifyBankDetails', {
        bankIdToFilter: getPaymentMethodAccount(selectedPaymentMethod)?.id,
        flow: PaymentFlow.PayNow,
      })
    }
  }

  const handleOnPressSubmit = async (): Promise<void> => {
    if (!selectedPaymentMethod || !doesPrimaryPaymentMethodHavePaymentInstrument) {
      handleOnPressRelinkAccount()
    }

    await executePayment().then(() => {
      TrackAppEvent(CardEvents.card_adhoc_payment_scheduled, AppEvents.Category.Card, {
        adhoc_payment_type: selectedButton?.trackingId,
        userSelectedButtonId,
        ...eventArgs,
      })
    })
  }

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: getPrimaryButtonText(),
          testID: 'Pay-Now-Button-Id',
          onPress: handleOnPressSubmit,
          disabled: Number(currentBalance) <= 0 || isLoading,
          loading: isLoading,
        },
      }}
      variant={'generic'}
      title={t('PayNow')}
      testID="Pay-Now-Page-Id"
      banner={(): null => null}
      smallTopGap
      noHorizontalPadding
      noHeaderSpacer
    >
      <CardActionListItem
        isDisabled={isLoading}
        onPress={(): void => navigation.goBack()}
        subTitle={selectedButton?.label}
        title={convertToDollarAmt(paymentState.paymentSpecification.amount)}
        isPadded
      />
      {selectedPaymentMethod ? (
        <PaymentMethodLink
          isDisabled={isLoading}
          isFirstChild={false}
          paymentMethod={selectedPaymentMethod}
          flow={PaymentFlow.PayNow}
          isPadded
        />
      ) : (
        <UnlinkedBankPaymentMethodLink
          isFirstChild={false}
          onPress={handleOnPressRelinkAccount}
          isPadded
        />
      )}

      <CardActionListItem
        subTitle={t('SoonestDate')}
        title={formatDate(moment().add(30, 'm'), friendlyDateWithDOW)}
        isPadded
      />

      <Box gap={'medium'} paddingHorizontal={'medium'} paddingTop={'medium'} grow>
        {
          // This logic is confusing but it's the safest render outcome to default to `null`
          accountPOTStatus !== undefined && accountPOTStatus !== CardAccountPOTStatus.NotPOT ? (
            <PFInfoCapsule
              svgIcon={{name: 'largeArrowUpRight', colorVariant: 'success'}}
              text={
                accountPOTStatus === CardAccountPOTStatus.OverdueOneMissedPayment ||
                accountPOTStatus === CardAccountPOTStatus.OverdueMultipleMissedPayments
                  ? t('InfoTextMissed')
                  : t('InfoText')
              }
            />
          ) : null
        }
        <Box flex={1} justify={'end'}>
          <PFText variant="p_sm" color={NamedColors.SILVER}>
            {t('WhenYouClick')}
          </PFText>
        </Box>
      </Box>
    </Page>
  )
}

export {CardAdhocPaymentReview}
