import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'

export type ConfirmDeclineCounterOfferOverlayProps = {
  onConfirmCancel: () => void
  onContinueWithLoan: () => void
  showCancelLoanConfirmation: boolean
}
const ConfirmDeclineCounterOfferOverlay: FC<ConfirmDeclineCounterOfferOverlayProps> = (props) => {
  const {
    onConfirmCancel,
    onContinueWithLoan: handleOnContinueWithLoan,
    showCancelLoanConfirmation,
  } = props
  const {t} = useTranslation('ConfirmDeclineCounterOffer')

  const handleOnConfirmCancel = (): void => {
    TrackAppEvent(AppEvents.Name.loan_counter_offer_declined, AppEvents.Category.Checkout)
    onConfirmCancel()
  }

  return (
    <OverlaySimple
      testID="Confirm-Decline-Counter-Offer-Overlay"
      visible={showCancelLoanConfirmation}
      title={t('AreYouSure')}
      okayButtonText={t('ContinueWithMyLoan')}
      onOkay={handleOnContinueWithLoan}
      dismissButtonText={t('IWantToCancelMyLoan')}
      onDismiss={handleOnConfirmCancel}
    />
  )
}

export {ConfirmDeclineCounterOfferOverlay}
