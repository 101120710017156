import React, {ReactElement, useEffect} from 'react'

import {
  LoanActiveStatusPaymentAlertItem,
  LoanActiveStatusPaymentAlerts,
} from '@possible/cassandra/src/types/types.mobile.generated'

import TileListView from 'src/products/loans/components/molecules/TileListView/TileListView'
import {
  LoanCalendarAndPaymentTileProps,
  LoanCalendarAndPaymentTile,
} from 'src/products/loans/Dashboard/LoanCalendarAndPaymentTile/LoanCalendarAndPaymentTile'
import {
  LoanProgressTileProps,
  LoanProgressTile,
} from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressTile'
import {LoanHistoryTile} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile'
import {LoanHistoryTileProps} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile.types'

import {
  LoanAlertTile,
  LoanAlertTileProps,
} from 'src/products/loans/Dashboard/LoanAlertTile/LoanAlertTile'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {LoanDashboardEvents} from 'src/lib/Analytics/app_events'
import {DashboardLoanActiveProps} from 'src/products/loans/Dashboard/DashboardLoanActive/DashboardLoanActive.types'
import {
  determineLoanAlertStatuses,
  useRedirectActiveLoanWebUserToAppIfAppropriate,
  useDashboardLoanActiveAlertsAnalytics,
} from 'src/products/loans/Dashboard/DashboardLoanActive/DashboardLoanActive.utils'

const DashboardLoanActive: React.FC<DashboardLoanActiveProps> = (
  props: DashboardLoanActiveProps,
) => {
  const {
    payments,
    amountProcessing,
    alerts,
    actions,
    disbursement,
    hasPreviousLoan,
    onUpdatePaymentDates,
    onMakeAPayment,
    onShowChargedOffExplanation,
    onShowDefaultExplanation,
    onContactUs,
    onRelinkBankAccount,
    onViewLoanHistory,
    onUpdatePaymentMethod,
  } = props
  const tiles: ReactElement[] = []
  // track when this dashboard is viewed
  useEffect(() => {
    TrackAppEvent(LoanDashboardEvents.active_dashboard_viewed, AppEvents.Category.LoanDashboard)
  }, [])
  useDashboardLoanActiveAlertsAnalytics({alerts})

  // some users on web should be sent back to the mobile app when their loan is active.
  // this is typically for Android users who were sent to web during loan application
  useRedirectActiveLoanWebUserToAppIfAppropriate()

  const {isChargedOff, isInDefault, isSuspended, hasPaymentMethodIssue} =
    determineLoanAlertStatuses({alerts})

  // note that alerts are ordered by the BE so we can just display as received
  alerts.items.forEach((thisAlert: LoanActiveStatusPaymentAlertItem) => {
    if (thisAlert.type !== LoanActiveStatusPaymentAlerts.None) {
      const thisAlertTileProps: LoanAlertTileProps = {
        alert: thisAlert,
        actions,
        loanPayments: payments,
        onRelinkBankAccount,
        onContactUs,
        onUpdateLoanPaymentDates: onUpdatePaymentDates,
        onMakeAPayment,
        onUpdatePaymentMethod,
        testID: `DashboardLoanActive-LoanAlertTile-${thisAlert.type}`,
      }
      tiles.push(<LoanAlertTile {...thisAlertTileProps} />)
    }
  })

  const loanCalendarTileProps: LoanCalendarAndPaymentTileProps = {
    isChargedOff,
    isInDefault,
    hasPaymentMethodIssue,
    loanPaymentDates: payments,
    actions,
    amountProcessing,
    onMakeAPayment,
    onUpdatePaymentDates,
    testID: 'DashboardLoanActive-LoanCalendarAndPaymentTile',
  }
  tiles.push(<LoanCalendarAndPaymentTile {...loanCalendarTileProps} />)

  const loanProgressTileProps: LoanProgressTileProps = {
    onShowDefaultExplanation,
    onShowChargedOffExplanation,
    onReschedule: onUpdatePaymentDates,
    onContactUs,
    onRelinkBankAccount,
    payments,
    amountProcessing,
    isInDefault,
    isChargedOff,
    isSuspended,
    fundingFailed: hasPaymentMethodIssue,
    testID: 'DashboardLoanActive-LoanProgressTile',
  }
  tiles.push(<LoanProgressTile {...loanProgressTileProps} />)

  const loanHistoryTileProps: LoanHistoryTileProps = {
    loanPayments: payments,
    hasPreviousLoan,
    onViewLoanHistory,
    loanDisbursement: disbursement,
    testID: 'DashboardLoanActive-LoanHistoryTile',
  }
  tiles.push(<LoanHistoryTile {...loanHistoryTileProps} />)

  return <TileListView testID="DashboardLoanActive" list={tiles} />
}

export {DashboardLoanActive}
