import {
  API_STATE_CHANGE_EVENT,
  CLEAR_USER_ACTION,
  MAINTENANCE_COMPLETED_V1,
  MAINTENANCE_COMPLETED_V2,
  MAINTENANCE_FIREBASE,
  MAINTENANCE_IN_PROGRESS_V1,
  MAINTENANCE_IN_PROGRESS_V2,
  MINIMUM_BINARY_VERSION,
  NETWORK_CONNECTION_FAILURE,
  NETWORK_CONNECTION_SUCCESS,
  STORE_USER_ID_TOKEN_ACTION,
  USER_LOGGED_IN_ACTION,
  USER_SESSION_EXPIRED,
} from 'src/api/actions/actionsNames'
import {ApiActionType, ApiReduxState, UserLoginStatesTypes} from 'src/api/reducers/types'

export const APIStateDefault: ApiReduxState = {
  dev_mode: false,
  init: false,
  user_logged_state: 'not_logged_in' as UserLoginStatesTypes,
  user_id: '',
  user_token: '',
  sessionExpired: false,
  instanceId: undefined,
  badConnection: false,
  maintenanceV1: false,
  maintenanceV2: false,
  maintenanceFirebase: false,
  initialUserRefresh: false, //This gets set to true after the users session starts and all the user / loan data is refreshed
}

export default function reducer(
  inState: ApiReduxState | undefined,
  action: ApiActionType,
): ApiReduxState {
  const state = inState ?? APIStateDefault

  switch (action.type) {
    case API_STATE_CHANGE_EVENT: {
      const newState: ApiReduxState = {...state, ...action.state}
      return newState
    }
    case CLEAR_USER_ACTION:
      return {
        ...state,
        user_id: undefined,
        user_token: undefined,
        user_logged_state: 'not_logged_in',
        initialUserRefresh: false,
      }
    case USER_LOGGED_IN_ACTION:
      return {
        ...state,
        user_logged_state: 'logged_in',
        sessionExpired: false,
      }
    case STORE_USER_ID_TOKEN_ACTION:
      return {
        ...state,
        user_id: action.user_id,
        user_token: action.token,
      }
    case USER_SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: true,
      }
    case NETWORK_CONNECTION_FAILURE:
      return {
        ...state,
        badConnection: true,
      }
    case NETWORK_CONNECTION_SUCCESS:
      return {
        ...state,
        badConnection: false,
      }
    case MAINTENANCE_IN_PROGRESS_V1:
      return {
        ...state,
        maintenanceV1: true,
      }
    case MAINTENANCE_COMPLETED_V1:
      return {
        ...state,
        maintenanceV1: false,
      }
    case MAINTENANCE_IN_PROGRESS_V2:
      return {
        ...state,
        maintenanceV2: true,
      }
    case MAINTENANCE_COMPLETED_V2:
      return {
        ...state,
        maintenanceV2: false,
      }
    case MAINTENANCE_FIREBASE:
      return {
        ...state,
        maintenanceFirebase: action.payload,
      }
    case MINIMUM_BINARY_VERSION:
      return {
        ...state,
        minimumBinaryVersion: action.payload,
      }
    default:
      return state
  }
}
