import {useCallback, useState} from 'react'

import {subscriptionManager} from 'src/lib/RealtimeDatabase/SubscriptionManager'
import {RealtimeDbProperties} from 'src/lib/RealtimeDatabase/RealtimeDb.types'

export const init = subscriptionManager.init

export const unsubscribeWhenAppIsInBackground = subscriptionManager.unsubscribeWhenAppIsInBackground

/**
 * Subscribe to the maintenance mode realtime db property.
 */
export const useMaintenanceMode = (): boolean => {
  const [isMaintenanceMode, setMaintenanceMode] = useState(false)
  const handleOnValueChange = useCallback((value) => {
    setMaintenanceMode(value === 'yes')
  }, [])
  subscriptionManager.useSubscribeToRealtimeDbProperty(
    RealtimeDbProperties.MaintenanceMode,
    handleOnValueChange,
  )
  return isMaintenanceMode
}
