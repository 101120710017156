import React, {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Trans, useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'
import timer from 'react-native-timer'
import {useNavigation} from '@react-navigation/native'

import {PhoneNumberVerificationMethod} from '@possible/generated/APIClient'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import HookForm, {FieldVariants} from 'src/designSystem/components/atoms/HookForm/HookForm'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {
  ButtonLockupProps,
  ButtonLockupPropsSecondary,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import Snackbar from 'src/lib/Snackbar'
import {openContactUsForm} from 'src/lib/contactUs'
import {getHasErrorsOrMissingValues, validLengthNumeral} from 'src/lib/utils/formValidationUtil'
import CodeSentConfirmation from 'src/products/loans/PhoneConfirmation/CodeSentConfirmation'
import {
  EMAIL,
  PHONE,
  VerificationMethod,
  VerificationStackNavigationPropType,
} from 'src/products/loans/PhoneConfirmation/PhoneConfirmation.types'
import {waitTime} from 'src/products/loans/PhoneConfirmation/ResendWaitingTime'

export type FormData = {
  verificationCode: number
}

type Props = {
  resendAction: (PhoneNumberVerificationMethod) => Promise<boolean>
  submitAction: (string) => Promise<void>
  parentBusy?: boolean
  formattedPhoneNumber?: string
  email?: string
  verificationMethod: VerificationMethod
}

const timerId = 'resendFrequency'
const resendFrequency = waitTime * 1000 * 60
const verificationCodeLength = 4

const GenericVerification: FC<Props> = (props) => {
  const {
    resendAction,
    submitAction,
    parentBusy = false,
    formattedPhoneNumber,
    email,
    verificationMethod,
  } = props
  const navigation = useNavigation<VerificationStackNavigationPropType>()

  const [busy, setBusy] = useState(false)
  const [resendBusy, setResendBusy] = useState(false)
  const [showingConfirmation, setShowingConfirmation] = useState(false)
  const [blockResend, setBlockResend] = useState(false)
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm<FormData>({mode: 'all'})
  const {t} = useTranslation(['PhoneConfirmation', 'Common'])

  useEffect(() => {
    return () => timer.clearTimeout(timerId)
  }, [])

  const closeConfirmation = () => {
    setShowingConfirmation(false)
  }

  const actionInProgress = () => busy || resendBusy || parentBusy

  const getVerificationMethod = (phoneMethod: PhoneNumberVerificationMethod) => {
    return verificationMethod === PHONE ? phoneMethod : EMAIL
  }

  const showConfirmation = (phoneMethod: PhoneNumberVerificationMethod) => {
    const method = getVerificationMethod(phoneMethod)
    return ShowLightbox(
      <CodeSentConfirmation
        navigation={navigation}
        method={method}
        dismissCallback={closeConfirmation}
      />,
      true,
    )
  }

  const timerCallback = () => {
    setBlockResend(false)
  }

  const showConfirmationIfSuccessful = (
    method: PhoneNumberVerificationMethod,
    successful: boolean,
  ): void => {
    if (!showingConfirmation && successful) {
      setShowingConfirmation(true)
      showConfirmation(method)
    }
  }

  const onResend = async (method: PhoneNumberVerificationMethod) => {
    if (!blockResend) {
      setBlockResend(true)
      setResendBusy(true)
      timer.setTimeout(timerId, () => timerCallback(), resendFrequency)

      const successful = await resendAction(method)
      showConfirmationIfSuccessful(method, successful)

      setResendBusy(false)
    } else {
      Snackbar.info({
        title: t('PleaseWaitTimeBeforeResending', {count: waitTime}),
        duration: Snackbar.LENGTH_LONG,
      })
    }
  }

  const onPress = async (data) => {
    setBusy(true)
    await submitAction(data.verificationCode)
    setBusy(false)
  }

  const isCodeValid = (newCode) => {
    return validLengthNumeral(verificationCodeLength, newCode)
  }

  const getDisableSubmit = () => {
    return getHasErrorsOrMissingValues(errors, watch) || actionInProgress()
  }

  const main_body = () => {
    if (verificationMethod === PHONE) {
      if (!formattedPhoneNumber) {
        return t('EnterCode')
      } else {
        return t('WeJustSentYouATextMessage', {phoneNumber: formattedPhoneNumber})
      }
    } else {
      return email ? t('WeJustSentYouAnEmail', {email}) : t('WeJustSentYouAnEmailGeneric')
    }
  }

  const fallbackCopy =
    verificationMethod === PHONE ? (
      <Box marginTop={'small'} gap={'tiny'} align={'center'}>
        <PFText variant={'p_sm'} color={NamedColors.SILVER}>
          {t('PhoneConfirmation:TextMessageNotComingThrough')}
        </PFText>
        <SvgLink
          disabled={actionInProgress()}
          linkIcon={'internal'}
          linkIconSize={'little'}
          linkText={t('Common:GetAPhoneCall')}
          linkType={'inline'}
          onPress={(): Promise<void> => onResend('VOICE')}
          textVariant={'p_sm'}
        />
        <SvgLink
          linkIcon={'internal'}
          linkIconSize={'little'}
          linkText={t('Common:ContactUsLowercaseU')}
          linkType="inline"
          onPress={(): void => {
            TrackAppEvent(
              AppEvents.Name.enter_verification_code_contact_us_selected,
              AppEvents.Category.Checkout,
            )
            openContactUsForm(navigation)
          }}
          textVariant={'p_sm'}
        />
      </Box>
    ) : (
      <PFText variant={'p'} textAlign={'center'}>
        <Trans i18nKey={'IfYouDoNotReceiveTheCode'} t={t}>
          {"If you don't receive the code within five minutes, please "}
          <SvgLink
            disabled={actionInProgress()}
            linkText={t('Common:ContactUsLowercase')}
            linkType={'inline'}
            onPress={(): void => openContactUsForm(navigation)}
          />
        </Trans>
      </PFText>
    )

  const verificationValidation = (value) =>
    !isCodeValid(value) ? t('verificationCodeNumeric') : undefined
  const formProps = {
    name: 'verificationCode',
    field: FieldVariants.TextField,
    rules: {
      required: t('verificationCodeRequired'),
      minLength: {
        value: verificationCodeLength,
        message: t('verificationCodeMinimum', {verificationCodeLength}),
      },
      validate: verificationValidation,
    },
  }

  const primary = {
    onPress: handleSubmit(onPress),
    text: t('SubmitVerificationCode'),
    disabled: getDisableSubmit(),
    loading: busy || parentBusy,
  }

  const secondary: ButtonLockupPropsSecondary = {
    buttonText: t('Common:ResendMyCode'),
    size: 'medium',
    onPress: () => {
      onResend('SMS')
    },
    color: 'link',
  }

  const buttonProps: ButtonLockupProps = {
    type: 'doubleButton',
    primary,
    secondary,
  }

  return (
    <Page
      variant={'generic'}
      smallTopGap={true}
      buttonProps={buttonProps}
      title={t('EnterYourVerificationCode')}
      description={main_body()}
      enabledKeyboardAvoidingView
    >
      <Box gap="medium">
        <Box justify={'center'} boxStyle={style.container}>
          <HookForm control={control} errors={errors}>
            <PFTextInput
              formProps={formProps}
              testID={'input_phone_verification_code'}
              returnKeyType="done"
              keyboardType="phone-pad"
              label={t('MyVerificationCode')}
              textContentType="oneTimeCode"
              maxLength={verificationCodeLength}
            />
          </HookForm>
        </Box>
      </Box>
      {fallbackCopy}
    </Page>
  )
}

export {GenericVerification}

const style = StyleSheet.create({
  container: {
    flex: 1,
  },
})
