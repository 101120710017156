import React, {FC, useCallback, useEffect} from 'react'
import {useFocusEffect} from '@react-navigation/native'

import {UpdateDisbursementMethod} from 'src/api/actions/loans/loanActions'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'

import {useCassandraLazyQuery, useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import AppEvents from 'src/lib/Analytics/app_events'
import {useRemoteValueJson} from 'src/lib/RemoteConfig'
import {INSTANT_DEBIT_ENABLED} from 'src/lib/RemoteConfig/parameterkeys'
import {transferMethods, transferMethodsType} from 'src/lib/loans/consts'
import {disbursementMethodSelectedSelector} from 'src/lib/loans/selector'
import {
  logOfferActivationError,
  logOfferActivationErrorAndShowException,
} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {
  LoanDisbursementMethodSelectionDocument,
  LoanDisbursementMethodSelectionSaveDocument,
} from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodSelection.gqls'
import {LoanDisbursementMethodSelectionTemplate} from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodSelectionTemplate'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {LoanPaymentMethod} from '@possible/cassandra/src/types/types.mobile.generated'
import {usePromise} from 'src/lib/usePromise/usePromise'

type Props = {
  onContinue: () => void | Promise<void>
  onHasActiveLoan?: () => void | Promise<void>
}

const LoanDisbursementMethodSelectionGQLContainer: FC<Props> = (props) => {
  const {onContinue, onHasActiveLoan} = props
  const dispatch = usePfDispatch()

  const [fetchQuery, {selectedData}] = useCassandraLazyQuery(
    LoanDisbursementMethodSelectionDocument,
    {
      onError: (e) => {
        logOfferActivationError(
          e,
          'LoanDisbursementMethodSelection failed to get latest loan status',
        )
      },
    },
    (from) => {
      return {
        loanId: from.me.loans.latestActionableLoan?.id,
        hasActiveLoan:
          from.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
          'ActiveLoanAggregateStatus',
      }
    },
  )
  const loanId = selectedData?.loanId

  const [submitSaveSelection] = useCassandraMutation(LoanDisbursementMethodSelectionSaveDocument)

  // the selected disbursement method is stored in redux until the loan is accepted on the back end
  const selectedDisbursementMethod:
    | Extract<transferMethodsType, 'ach' | 'interchange'>
    | undefined = usePfSelector(disbursementMethodSelectedSelector)

  const instantDebitRemoteValue = useRemoteValueJson(INSTANT_DEBIT_ENABLED)
  const isInstantDebitDisabled = instantDebitRemoteValue === false

  const fetchQueryToCheckForActiveLoan = useCallback(() => {
    void fetchQuery().then((res) => {
      // if we somehow end up here with an active loan the parent may want to be notified
      if (res.selectedData?.hasActiveLoan) {
        logOfferActivationError(
          'LoanDisbursementMethodSelection encountered when user has an active loan',
        )
        void onHasActiveLoan?.()
      }
    })
  }, [fetchQuery, onHasActiveLoan])

  useFocusEffect(fetchQueryToCheckForActiveLoan)

  const handleOnSelectDebitCardOption = (): void => {
    const paymentMethod = transferMethods.interchange
    TrackAppEvent(AppEvents.Name.disbursement_method_selected, AppEvents.Category.Checkout, {
      paymentMethod,
    })
    void dispatch(UpdateDisbursementMethod(paymentMethod))
  }

  const handleOnSelectAchOption = useCallback((): void => {
    const paymentMethod = transferMethods.ach
    TrackAppEvent(AppEvents.Name.disbursement_method_selected, AppEvents.Category.Checkout, {
      paymentMethod,
    })
    void dispatch(UpdateDisbursementMethod(paymentMethod))
  }, [dispatch])

  const [handleOnContinue, {isLoading: isHandleOnContinueExecuting}] = usePromise(
    async (): Promise<void> => {
      TrackAppEvent(AppEvents.Name.disbursement_method_confirmed, AppEvents.Category.Checkout, {
        paymentMethod: selectedDisbursementMethod,
      })

      try {
        if (!loanId) {
          throw new Error('no loanId found')
        }
        let paymentMethod: LoanPaymentMethod | undefined
        switch (selectedDisbursementMethod) {
          case 'ach':
            paymentMethod = LoanPaymentMethod.Ach
            break
          case 'interchange':
            paymentMethod = LoanPaymentMethod.DebitCard
            break
          default:
            paymentMethod = undefined
            break
        }
        if (!paymentMethod) {
          throw new Error(
            `no valid payment method found, selectedDisbursementMethod="${selectedDisbursementMethod}"`,
          )
        }
        await submitSaveSelection({
          variables: {
            input: {
              loanId,
              disbursementMethod: paymentMethod,
            },
          },
        })
        await onContinue()
      } catch (e) {
        logOfferActivationErrorAndShowException(
          e,
          'LoanDisbursementMethodSelection failed to save loan disbursement method selection',
        )
      }
    },
  )

  // if debit card is disabled we only allow ach
  useEffect(() => {
    if (isInstantDebitDisabled && selectedDisbursementMethod !== 'ach') {
      handleOnSelectAchOption()
    }
  }, [selectedDisbursementMethod, handleOnSelectAchOption, isInstantDebitDisabled])

  return (
    <LoanDisbursementMethodSelectionTemplate
      isContinueBtnDisabled={!selectedDisbursementMethod}
      isInstantDebitDisabled={isInstantDebitDisabled}
      isSubmitting={isHandleOnContinueExecuting}
      onSelectDebitCard={handleOnSelectDebitCardOption}
      onSelectAch={handleOnSelectAchOption}
      onContinue={async (): Promise<void> => {
        await handleOnContinue()
      }}
      selectedMethod={selectedDisbursementMethod}
    />
  )
}

export {LoanDisbursementMethodSelectionGQLContainer}
