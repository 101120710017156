import React, {ReactElement} from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents from 'src/lib/Analytics/app_events'

import {AcceptLoanAndAutoPayAgreementsGQLContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndAutoPayAgreementsBase/AcceptLoanAndAutoPayAgreementsGQLContainer'
import {AcceptAutoPayAgreementTemplate} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptAutoPayAgreement/AcceptAutoPayAgreementTemplate'

import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export type AcceptAutoPayAgreementWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container to use the AcceptAutoPayAgreement screen within Workflows.
 */
const AcceptAutoPayAgreementWorkflowContainer: React.FC<
  AcceptAutoPayAgreementWorkflowContainerProps
> = (props: AcceptAutoPayAgreementWorkflowContainerProps) => {
  const {onPreReqFulfilled} = props
  const handleOnAcceptAndContinue = async (): Promise<void> => {
    await onPreReqFulfilled()
  }
  usePageViewedAnalytics({
    eventName: AppEvents.Name.accept_agreements_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'Workflows',
      agreements: 'autopay_only',
    },
  })
  return (
    <AcceptLoanAndAutoPayAgreementsGQLContainer
      testID="AcceptAutoPayAgreementWorkflowContainer"
      onAcceptAndContinue={handleOnAcceptAndContinue}
      render={(innerProps): ReactElement => <AcceptAutoPayAgreementTemplate {...innerProps} />}
    />
  )
}

export {AcceptAutoPayAgreementWorkflowContainer}
