import {getDatabase, ref, onValue, off} from 'firebase/database'

import Firebase from 'src/lib/firebase'
import {RealtimeDbProperty} from 'src/lib/RealtimeDatabase/RealtimeDb.types'
import {RealtimeDatabaseAdapterInterface} from 'src/lib/RealtimeDatabase/RealtimeDbAdapter/RealtimeDbAdapterInterface'

const subscribe = (
  dbProperty: RealtimeDbProperty,
  onPropertyValueChanged: (dbProperty: RealtimeDbProperty, maintenanceModeValue: unknown) => void,
): void => {
  const dbRef = ref(getDatabase(Firebase.getApp()), dbProperty)
  onValue(dbRef, (snapshot) => {
    // set the local state using the subscription value when it changes
    // call all the onValueChange listeners
    onPropertyValueChanged(dbProperty, snapshot.val())
  })
}

const unsubscribe = (dbProperty: RealtimeDbProperty): void => {
  const dbRef = ref(getDatabase(Firebase.getApp()), dbProperty)
  off(dbRef, 'value')
}

const init = (): void => {
  // no need for init on web. subscribe/unsubscribe is enough since we don't
  // sub to binary version at app startup on web like we do on mobile
}

const unsubscribeBinaryVersion = (): void => {
  // we don't do this on web
}

const webRealtimeDbAdapter: RealtimeDatabaseAdapterInterface = {
  init,
  subscribe,
  unsubscribe,
  unsubscribeBinaryVersion,
}
export {webRealtimeDbAdapter as realtimeDatabaseAdapter}
