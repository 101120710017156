import React, {FC, useState} from 'react'

import {logOfferActivationError} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {openContactUsForm} from 'src/lib/contactUs'
import {RelinkPreferredBankAccountGQLContainer} from 'src/products/loans/LoanApprovedActivation/RelinkPreferredBankAccount/RelinkPreferredBankAccountGQLContainer'
import {useBankAggregator} from 'src/products/general/GeneralPaymentMethods/useBankAggregator'
import {useMainNavigator} from 'src/nav/AppNavHelper'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'

export type RelinkPreferredBankAccountWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

const RelinkPreferredBankAccountWorkflowContainer: FC<
  RelinkPreferredBankAccountWorkflowContainerProps
> = (props) => {
  const {onPreReqFulfilled} = props
  const mainStackNav = useMainNavigator()

  const [isFulfillingPreReq, setIsFulfillingPreReq] = useState(false)

  usePageViewedAnalytics({
    eventName: AppEvents.Name.relink_preferred_account_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'Workflows',
    },
  })

  const {openBankAggregator} = useBankAggregator(mainStackNav)

  const handleOnContactUs = (): void => openContactUsForm(mainStackNav)

  const handleOnOpenBankAggregator = (preferredAccountId: string): void => {
    openBankAggregator({
      account: {id: preferredAccountId},
      onBankLinkComplete: async () => {
        try {
          setIsFulfillingPreReq(true)
          TrackAppEvent(
            AppEvents.Name.relink_preferred_account_screen_completed,
            AppEvents.Category.Checkout,
          )
          await onPreReqFulfilled()
        } catch (error) {
          logOfferActivationError(error, 'Error while checking PreReq status')
        } finally {
          setIsFulfillingPreReq(false)
        }
      },
    })
  }

  return (
    <RelinkPreferredBankAccountGQLContainer
      onOpenBankAggregator={handleOnOpenBankAggregator}
      onContactUs={handleOnContactUs}
      isParentLoading={isFulfillingPreReq}
    />
  )
}

export {RelinkPreferredBankAccountWorkflowContainer}
