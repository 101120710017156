import {AnalyticsDisabled} from 'src/lib/Analytics/Analytics.utils'
import {DefaultAnalyticDestinations} from 'src/lib/EventStream/EventStream.constants'
import {
  EventStreamEventType,
  EventStreamInterface,
  EventStreamListenerCallback,
  EventStreamMetadata,
  InferredEventStreamEvent,
} from 'src/lib/EventStream/EventStream.types'
import {isAnalyticEvent, isMetadataEvent} from 'src/lib/EventStream/EventStream.utils'

type EventStreamObservers = {[key in EventStreamEventType]: EventStreamListenerCallback<key>[]}

/**
 * EventStream allows for listening to and emitting events throughout the app. It is
 * exposed through the `EventStreamSingleton`.
 * It is used for logging, analytics, attribution, and metadata.
 *
 * Create a Service that listens to the EventStream and emits events to the EventStream.
 * See AmplitudeService and DatadogService for examples.
 */
class EventStream implements EventStreamInterface {
  observers: EventStreamObservers = {
    log: [],
    analytic: [],
    metadata: [],
  }

  _metadata: EventStreamMetadata = {}

  /**
   * Add a listener callback that will be invoked
   * when an event of the specified type is emitted.
   * @param eventType The type of event for which to list
   * @param callback The callback to invoke when the event is emitted
   * @returns
   */
  addListener<Type extends EventStreamEventType>(
    eventType: Type,
    callback: EventStreamListenerCallback<Type>,
  ): void {
    if (this.observers[eventType].includes(callback)) {
      return
    }

    this.observers[eventType].push(callback)
    if (eventType === 'metadata') {
      // prettier insists we need this semicolon,
      // but eslint dislikes it
      // eslint-disable-next-line no-extra-semi, no-type-assertion/no-type-assertion
      ;(callback as EventStreamListenerCallback<'metadata'>)({
        type: 'metadata',
        data: this._metadata,
      })
    }
  }

  /**
   * Emit an event to all listeners of the specified type.
   * @param event The event to emit
   * @returns
   */
  emit<Type extends EventStreamEventType>(event: InferredEventStreamEvent<Type>): void {
    // we want to keep our metadata up to date
    if (isMetadataEvent(event)) {
      this._metadata = {...this._metadata, ...event.data}
      event.data = this._metadata
    }

    if (isAnalyticEvent(event)) {
      // drop any analytic events if analytics are disabled
      if (AnalyticsDisabled()) {
        return
      }

      // for analytic events we want to default to sending to amplitude and braze
      event.destinations = event.destinations ?? DefaultAnalyticDestinations
    }

    this.observers[event.type].forEach((callback) =>
      // eslint-disable-next-line no-type-assertion/no-type-assertion
      (callback as EventStreamListenerCallback<Type>)(event),
    )
  }

  /**
   * Remove a listener callback for the specified event type.
   * @param eventType The type of event for which to remove the listener
   * @param callback The callback to remove
   * @returns
   */
  removeListener<Type extends EventStreamEventType>(
    eventType: Type,
    callback: EventStreamListenerCallback<Type>,
  ): void {
    const index = this.observers[eventType].indexOf(callback)
    if (index === -1) {
      this.emit({type: 'log', severity: 'warn', data: 'Tried to remove non-existent listener'})
      return
    }

    this.observers[eventType].splice(index, 1)
  }
}

/**
 * EventStreamSingleton allows for listening to and emitting events throughout the app.
 * It is used for logging, analytics, attribution, and metadata.
 *
 * Create a Service that listens to the EventStream and emits events to the EventStream.
 * See AmplitudeService (via TrackAppEvent) and DatadogService (via loggingUtil) for examples.
 */
const EventStreamSingleton = new EventStream()
export default EventStreamSingleton

export {EventStream}
