import {StateCodes} from '@possible/cassandra/src/types/types.mobile.generated'
import {
  useCassandraLazyQuery,
  useCassandraMutation,
  useCassandraQuery,
} from '@possible/cassandra/src/utils/hooks'
import React from 'react'

import {usePromise} from 'src/lib/usePromise/usePromise'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {LoanAmountSelectionTemplateNew} from 'src/products/loans/LoanAmountSelectionNew/LoanAmountSelectionTemplateNew'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {
  LoanAmountSelectionEstimatedCostNewDocument,
  LoanAmountSelectionSetLoanAmountNewDocument,
  LoanAmountSelectionNewDocument,
  LoanAmountSelectionTermsNewDocument,
  LoanAmountSelectionNewQuery,
} from 'src/products/loans/LoanAmountSelectionNew/LoanAmountSelectionNew.gqls'
import {
  logOfferApplicationError,
  logOfferApplicationErrorAndShowException,
} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {LoanSelectionCompleted, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'

function isStateCode(value?: string | null): value is StateCodes {
  if (!value) {
    return false
  }
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  return Object.values(StateCodes).includes(value as StateCodes)
}

function stringToStateCode(value?: string | null): StateCodes | undefined {
  const upperCaseValue = value?.toUpperCase()
  if (isStateCode(upperCaseValue)) {
    return upperCaseValue
  }
  return undefined
}

export const LoanAmountSelectionContainerNew: React.FC<WorkflowPreReqFulfillScreenProps> = (
  props,
) => {
  const {onPreReqFulfilled} = props

  const selectUserState = (data: LoanAmountSelectionNewQuery): StateCodes | undefined => {
    return stringToStateCode(
      // Must check profile value first, and use || instead of ?? since the state could be an empty string
      data.me.profile?.home?.address?.state || data.me.onboarding?.loan?.stateSelected,
    )
  }
  // Gets today's date just once on component mount
  const [hasStateCodeError, setHasStateCodeError] = React.useState<boolean>(false)
  const assessmentDate = React.useRef(new Date().toISOString()).current
  const {
    selectedData: queryData,
    loading: isQueryLoading,
    error: queryError,
  } = useCassandraQuery(
    LoanAmountSelectionNewDocument,
    {
      variables: {assessmentDate},
      fetchPolicy: 'network-only',
      onError: (e) => {
        logOfferApplicationError(
          e,
          'LoanAmountSelectionContainerNew: LoanAmountSelectionNew query failed',
        )
      },
      onCompleted: (data) => {
        const stateCode = selectUserState(data)
        if (!stateCode) {
          logOfferApplicationError(
            new Error('LoanAmountSelectionContainerNew: stateCode not found'),
          )
          setHasStateCodeError(true)
          return
        }
        setHasStateCodeError(false)
        void getTerms({
          variables: {
            state: stateCode,
          },
        })
      },
    },
    (data) => {
      const prequalifiedAmount = data.loanGetPrequalification.amount.split('.')[0]
      return {
        userStateCode: selectUserState(data),
        prequalifiedAmount: prequalifiedAmount === '0' ? undefined : prequalifiedAmount,
        isRepeatLoan: data.me.loans.all && data.me.loans.all.length > 0,
      }
    },
  )

  usePageViewedAnalytics({
    eventName: AppEvents.Name.loan_amount_selection_screen_viewed,
    eventCategory: queryData?.isRepeatLoan
      ? AppEvents.Category.Reapplication
      : AppEvents.Category.InitialApplication,
    isLoading: isQueryLoading,
  })

  const [getTerms, {selectedData: termsData, loading: isLoadingTerms, error: termsError}] =
    useCassandraLazyQuery(
      LoanAmountSelectionTermsNewDocument,
      {
        fetchPolicy: 'cache-first',
        onCompleted: (data) => {
          const terms = data.getPrimaryLoanTypeByState?.terms
          if (!terms) {
            logOfferApplicationError(new Error('LoanAmountSelectionContainerNew: terms not found'))
            return
          }

          if (queryData?.prequalifiedAmount) {
            handleOnLoanAmountChanged(queryData.prequalifiedAmount)
            return
          }

          handleOnLoanAmountChanged(terms.defaultAmount.split('.')[0])
        },
      },
      (data) => {
        const terms = data.getPrimaryLoanTypeByState?.terms
        if (!terms) {
          return undefined
        }
        return {
          minimumAmount: terms.minimumAmount.split('.')[0],
          maximumAmount: terms.maximumAmount.split('.')[0],
        }
      },
    )
  // BaseTemplate will hide these default values, but needed for typescript
  const minLoanAmount = termsData?.minimumAmount ?? '50'
  const maxLoanAmount = termsData?.maximumAmount ?? '800'

  // Get the estimated costs for the loan amount entered
  const [loanAmount, setLoanAmount] = React.useState<string>('')
  const [isValidAmount, setIsValidAmount] = React.useState<boolean>(true)

  const [getEstimatedCosts, {selectedData: estimatedCostsData, loading: isLoadingEstimatedCosts}] =
    useCassandraLazyQuery(
      LoanAmountSelectionEstimatedCostNewDocument,
      {
        fetchPolicy: 'cache-first',
      },
      (data) => data.loanEstimatedCosts,
    )
  // Estimated costs are 0 if the amount is invalid
  let apr = '0'
  let totalCost = '0'
  let totalOwed = '0'
  let installmentAmount = '0'
  let installmentCount = 0
  if (estimatedCostsData && isValidAmount) {
    apr = estimatedCostsData.apr
    totalCost = estimatedCostsData.totalCost
    totalOwed = estimatedCostsData.totalOwed
    installmentAmount = estimatedCostsData.installmentAmount
    installmentCount = estimatedCostsData.installmentCount
  }

  const validate = (amount: string): boolean => {
    const parsedValue = parseInt(amount)
    return (
      !isNaN(parsedValue) &&
      parsedValue >= parseInt(minLoanAmount) &&
      parsedValue <= parseInt(maxLoanAmount)
    )
  }

  const handleOnLoanAmountChanged = (amount: string): void => {
    if (!queryData?.userStateCode) {
      // BaseTemplate should prevent this from happening
      logOfferApplicationError(
        new Error('LoanAmountSelectionContainerNew: userStateCode not found'),
      )
      return
    }

    setLoanAmount(amount)

    const isValid = validate(amount)
    setIsValidAmount(isValid)
    if (isValid) {
      void getEstimatedCosts({variables: {state: queryData.userStateCode, amount}})
    }
  }

  const [handleOnPreReqFulfilled, {isLoading: isLoadingOnPreReqFulfilled}] = usePromise(
    async () => {
      await onPreReqFulfilled()

      TrackAppEvent(
        AppEvents.Name.loan_amount_selected,
        queryData?.isRepeatLoan
          ? AppEvents.Category.Reapplication
          : AppEvents.Category.InitialApplication,
        {
          value: loanAmount,
          loanTermsMinAmount: termsData?.minimumAmount,
          loanTermsMaxAmount: termsData?.maximumAmount,
        },
      )
      LoanSelectionCompleted()
    },
    {
      catch: (error) => {
        logOfferApplicationErrorAndShowException(
          error,
          'LoanAmountSelectionContainerNew: Error fulfilling workflow prerequisite.',
        )
      },
    },
  )
  const [submitLoanAmountMutation, {loading: isSubmittingAmount}] = useCassandraMutation(
    LoanAmountSelectionSetLoanAmountNewDocument,
    {
      onError: (error) => {
        logOfferApplicationErrorAndShowException(
          error,
          'LoanAmountSelectionContainerNew: Error submitting loan amount.',
        )
      },
    },
  )
  async function handleOnAmountSelected(amount: string): Promise<void> {
    const response = await submitLoanAmountMutation({variables: {amount}})
    if (response.errors) {
      return
    }

    await handleOnPreReqFulfilled()
  }

  return (
    <BaseTemplate
      testID={'LoanAmountSelectionNew'}
      isLoading={isQueryLoading || isLoadingTerms}
      isError={!!queryError || hasStateCodeError || !!termsError}
    >
      <LoanAmountSelectionTemplateNew
        prequalifiedAmount={queryData?.prequalifiedAmount}
        maxLoanAmount={maxLoanAmount}
        minLoanAmount={minLoanAmount}
        estimatedTotal={totalOwed}
        installmentAmount={installmentAmount}
        totalInterestAndFees={totalCost}
        APRPercent={apr}
        buttonAmounts={[minLoanAmount, '100', '200', maxLoanAmount]}
        onContinue={handleOnAmountSelected}
        isLoadingOnContinue={isSubmittingAmount || isLoadingOnPreReqFulfilled}
        isLoadingEstimatedCosts={isLoadingEstimatedCosts}
        loanAmount={loanAmount}
        onLoanAmountChanged={handleOnLoanAmountChanged}
        isValidAmount={isValidAmount}
        installmentCount={installmentCount}
      />
    </BaseTemplate>
  )
}
