import React, {ReactNode, useEffect} from 'react'
import {View} from 'react-native'

import TileListView from 'src/products/loans/components/molecules/TileListView/TileListView'
import {LoanDecisioningTile} from 'src/products/loans/Dashboard/LoanDecisioningTile/LoanDecisioningTile'
import {LoanDecisioningDelayedTile} from 'src/products/loans/Dashboard/LoanDecisioningDelayedTile/LoanDecisioningDelayedTile'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {LoanDashboardEvents} from 'src/lib/Analytics/app_events'
import {usePrevious} from 'src/lib/utils/hooks'
import {DashboardLoanDecisioningProps} from 'src/products/loans/Dashboard/DashboardLoanDecisioning/DashboardLoanDecisioning.types'

/**
 * Dashboard displayed when a loan is in the "DECISIONING" aggregate status.
 */
const DashboardLoanDecisioning: React.FC<DashboardLoanDecisioningProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {delayed} = props
  const tiles: ReactNode[] = []
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const previousDelayed = usePrevious(delayed)
  useEffect(() => {
    if (previousDelayed !== delayed) {
      // first time we encounter a new 'delayed' value we track that we've seen this screen.
      // that way we track the first time they viewed this screen, or when it moves from delayed=false to delayed=true
      TrackAppEvent(
        LoanDashboardEvents.decisioning_dashboard_viewed,
        AppEvents.Category.LoanDashboard,
        {delayed},
      )
    }
  }, [delayed, previousDelayed])
  if (delayed) {
    tiles.push(
      <View testID="DashboardLoanDecisioning-LoanDecisioningDelayedTile">
        <LoanDecisioningDelayedTile />
      </View>,
    )
  } else {
    tiles.push(
      <View testID="DashboardLoanDecisioning-LoanDecisioningTile">
        <LoanDecisioningTile />
      </View>,
    )
  }
  return <TileListView testID="DashboardLoanDecisioning" list={tiles} />
}

export {DashboardLoanDecisioning}
