/* eslint-disable no-type-assertion/no-type-assertion */
import {processReferral} from '@possible/cassandra/src/user/methods'
import {useEffect, useState} from 'react'

import {userIdSelector, userIsLoggedIn} from 'src/api/selectors/selectors'
import Log from 'src/lib/loggingUtil'
import RNFS from 'src/lib/RNFS/RNFS'
import {getPfStore} from 'src/store'
import AppNav from 'src/nav/AppNavActions'
import {PfReduxState} from 'src/reducers/types'
import {logErrorAndShowException} from 'src/lib/errors'
import AppEvents from 'src/lib/Analytics/app_events'
import EventStreamSingleton from 'src/lib/EventStream/EventStream'

// NOTE
// We send analytic events using the EventStream directly
// rather than using the `TrackAppEvent` helper method because
// doing so allows us to avoid a cyclical dependency. Once Singular
// is converted to a standalone service and is no longer managed
// directly by packages/mobile/src/lib/Analytics/analytics_compat.ts
// we can change it back.

// carbon copy of the react native package's SingularLinkParams type
type SingularLinkParams = {
  deeplink: string
  passthrough: string
  isDeferred: boolean
}

type PossibleLinkParams = Omit<SingularLinkParams, 'isDeferred'> & {
  isDeferred?: SingularLinkParams['isDeferred']
}

type DeeplinkType =
  | 'referral'
  | 'cvv'
  | 'offer'
  | 'loan_documents'
  | 'card_landing'
  | 'account_recovery'
  | 'account_management'
  | 'contactus'

/**
 * To determine which deeplink type is being parsed
 * we check if the `passthrough` field includes the value
 * in the key-value pair.
 */
const deeplinkTypeToIncludesKeywordMap: Record<DeeplinkType, string> = {
  referral: 'referral_code',
  cvv: 'cvv_screen',
  offer: 'offer_id',
  loan_documents: 'loan_documents',
  card_landing: 'card_landing',
  account_recovery: 'account_recovery',
  account_management: 'account_management',
  contactus: 'contactus',
}

type ReferralDeeplink = {
  type: Extract<DeeplinkType, 'referral'>
  path: string
  params: {
    referring_user_id: string
    referral_code: string
  }
}

type CVVDeeplink = {
  type: Extract<DeeplinkType, 'cvv'>
  path: string
}

type PartnerOfferDeeplink = {
  type: Extract<DeeplinkType, 'offer'>
  path: string
  params: {
    offer_id: string
    offer_ordinal: string
  }
}

type LoanDocumentsDeeplink = {
  type: Extract<DeeplinkType, 'loan_documents'>
  path: string
}

type CardLandingDeeplink = {
  type: Extract<DeeplinkType, 'card_landing'>
  path: string
}

type AccountRecoveryDeeplink = {
  type: Extract<DeeplinkType, 'account_recovery'>
  path: string
}

type AccountManagementDeeplink = {
  type: Extract<DeeplinkType, 'account_management'>
  path: string
}

type ContactUsDeeplink = {
  type: Extract<DeeplinkType, 'contactus'>
  path: string
}

type Deeplink =
  | ReferralDeeplink
  | CVVDeeplink
  | PartnerOfferDeeplink
  | LoanDocumentsDeeplink
  | CardLandingDeeplink
  | AccountRecoveryDeeplink
  | AccountManagementDeeplink
  | ContactUsDeeplink

type InferredDeeplink<T extends DeeplinkType> = {type: T} & Deeplink

type DeeplinkCache = Partial<Record<DeeplinkType, Deeplink>>
let cachedDeeplinks: DeeplinkCache = {}
const singularCachePath = `${RNFS.DocumentDirectoryPath}/singular_saved_deeplink`

function goToActivatePhysicalCardScreen(): void {
  AppNav.pushToMainStack('CardActivatePhysicalCard')
}

function goToLoanHistoryScreen(): void {
  AppNav.pushToMainStack('LoanHistory')
}

const goToPartnerLandingScreen = (): void => {
  AppNav.resetMainNavigation([{routeName: 'Landing', params: {}}])
}

const goToCardLandingScreen = (): void => {
  AppNav.resetMainNavigation([{routeName: 'CardLandingWeb', params: {}}])
}

const goToTempPasswordScreen = (): void => {
  AppNav.resetMainNavigation([{routeName: 'TemporaryPassword', params: {}}])
}

const goToAccountManagementScreen = (): void => {
  AppNav.resetMainNavigation([{routeName: 'AccountManagementV2', params: {}}])
}

const goToContactUsScreen = (): void => {
  AppNav.newModal('ZendeskHelpCenter', {})
}

export const initDeeplinks = async (): Promise<void> => {
  await readCache()
}

const readCache = async (): Promise<Partial<Record<DeeplinkType, Deeplink>> | undefined> => {
  try {
    const doesFileExist = await RNFS.exists(singularCachePath)
    if (doesFileExist) {
      const deeplinkPayload = await RNFS.readFile(singularCachePath, 'utf8')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      cachedDeeplinks = JSON.parse(deeplinkPayload)
      UpdateCacheObservers(cachedDeeplinks)

      for (const deeplink of Object.values(cachedDeeplinks)) {
        EventStreamSingleton.emit({
          type: 'analytic',
          name: AppEvents.Name.deeplink_read_from_storage,
          category: AppEvents.Category.Marketing,
          data: deeplink,
          destinations: ['amplitude'],
        })
      }

      return cachedDeeplinks
    }

    return undefined
  } catch (_e) {
    Log.warn('failed to initialize deeplink cache:', _e)
  }
}

const saveCache = async (): Promise<void> => {
  try {
    UpdateCacheObservers(cachedDeeplinks)

    // do we need a mutex here?
    const payload = JSON.stringify(cachedDeeplinks)
    await RNFS.writeFile(singularCachePath, payload, 'utf8')
  } catch (e) {
    Log.warn('failed to save cache: ', e)
  }
}

export const addDeepLink = (deeplink: Deeplink): void => {
  EventStreamSingleton.emit({
    type: 'analytic',
    name: AppEvents.Name.deeplink_saved_to_storage,
    category: AppEvents.Category.Marketing,
    data: deeplink,
    destinations: ['amplitude'],
  })
  cachedDeeplinks[deeplink.type] = deeplink
  void saveCache()
}

export const getDeeplink = <T extends DeeplinkType>(type: T): InferredDeeplink<T> | undefined => {
  const d = cachedDeeplinks[type]
  if (d) {
    return d as InferredDeeplink<T>
  }

  return undefined
}

export const clearDeeplink = async <T extends DeeplinkType>(
  deeplink: InferredDeeplink<T>,
): Promise<void> => {
  EventStreamSingleton.emit({
    type: 'analytic',
    name: AppEvents.Name.deeplink_cleared,
    category: AppEvents.Category.Marketing,
    data: deeplink,
    destinations: ['amplitude'],
  })
  cachedDeeplinks[deeplink.type] = undefined
  await saveCache()
}

const singularLinkParamsToDeeplink = (
  singularLinksParams: PossibleLinkParams,
): Deeplink | undefined => {
  const {deeplink, passthrough} = singularLinksParams
  for (const k in deeplinkTypeToIncludesKeywordMap) {
    const keyword = deeplinkTypeToIncludesKeywordMap[k]
    const emptyPassthrough = passthrough === null || passthrough?.length === 0
    if ((emptyPassthrough && deeplink?.includes(keyword)) || passthrough?.includes(keyword)) {
      let params = undefined
      try {
        params = JSON.parse(passthrough)
      } catch (e) {
        // expected behavior if _p isn't json.
        // carry on
      }

      const dl = {
        type: k,
        path: deeplink,
        params: params,
      }

      return dl as Deeplink
    }
  }

  return undefined
}

const processNavigation = (
  loggedIn: boolean,
  deeplink: Deeplink,
  navigationFunc: () => void,
): void => {
  if (loggedIn) {
    void clearDeeplink(deeplink)
    navigationFunc()
  }
}

type ProcessDeeplinkFunction<T extends DeeplinkType> = (
  deeplink: InferredDeeplink<T>,
  loggedIn: boolean,
  state: PfReduxState,
) => void

const unableToProcessDeepLinkMessage = 'unable to process deep link'

const processReferralDeeplink: ProcessDeeplinkFunction<'referral'> = (
  deeplink,
  loggedIn,
  state,
) => {
  const {params} = deeplink
  if (params.referring_user_id) {
    const refereeId = userIdSelector(state)
    if (loggedIn && refereeId !== params.referring_user_id) {
      try {
        void processReferral(params.referring_user_id, params.referral_code ?? '')
        void clearDeeplink(deeplink)
      } catch (e) {
        void logErrorAndShowException(e as Error, unableToProcessDeepLinkMessage)
      }
    }
  }
}

const processOfferDeeplink: ProcessDeeplinkFunction<'offer'> = (deeplink, loggedIn) => {
  if (!loggedIn) {
    goToPartnerLandingScreen()
  } else {
    void clearDeeplink(deeplink)
  }
}

const processCardLandingDeeplink: ProcessDeeplinkFunction<'card_landing'> = (
  deeplink,
  loggedIn,
) => {
  if (loggedIn) {
    goToCardLandingScreen()
  }
}

const processAccountRecoveryDeeplink: ProcessDeeplinkFunction<'account_recovery'> = (
  deeplink,
  loggedIn,
) => {
  if (loggedIn) {
    void clearDeeplink(deeplink)
  } else {
    goToTempPasswordScreen()
  }
}

const processAccountManagementDeeplink: ProcessDeeplinkFunction<'account_management'> = (
  deeplink,
  loggedIn,
) => {
  if (loggedIn) {
    goToAccountManagementScreen()
  }
}

const processContactUsDeeplink: ProcessDeeplinkFunction<'contactus'> = (deeplink, loggedIn) => {
  if (loggedIn) {
    goToContactUsScreen()
  }
}

export function processSingularDeepLink(singularLinksParams: PossibleLinkParams): void {
  try {
    EventStreamSingleton.emit({
      type: 'analytic',
      name: AppEvents.Name.deeplink_received,
      category: AppEvents.Category.Marketing,
      data: singularLinksParams,
      destinations: ['amplitude'],
    })

    const sDeeplink = singularLinkParamsToDeeplink(singularLinksParams)

    if (sDeeplink?.type) {
      addDeepLink(sDeeplink)
    }

    //this will handle the deeplink if the app is already open
    executeDeeplinkActions()
  } catch (e) {
    void logErrorAndShowException(
      e as Error,
      `Error parsing deeplink: ${singularLinksParams.deeplink}`,
    )
  }
}

export function executeDeeplinkActions(): void {
  const state = getPfStore()?.getState()
  if (!state) {
    return
  }

  try {
    for (const deeplink of Object.values(cachedDeeplinks)) {
      const isLoggedIn = userIsLoggedIn(state)

      if (deeplink?.type) {
        switch (deeplink.type) {
          case 'referral': {
            processReferralDeeplink(deeplink, isLoggedIn, state)
            return
          }

          case 'loan_documents': {
            processNavigation(isLoggedIn, deeplink, goToLoanHistoryScreen)
            return
          }

          case 'cvv': {
            processNavigation(isLoggedIn, deeplink, goToActivatePhysicalCardScreen)
            return
          }

          case 'offer': {
            processOfferDeeplink(deeplink, isLoggedIn, state)
            return
          }

          case 'card_landing': {
            processCardLandingDeeplink(deeplink, isLoggedIn, state)
            return
          }

          case 'account_recovery': {
            processAccountRecoveryDeeplink(deeplink, isLoggedIn, state)
            return
          }

          case 'account_management': {
            processAccountManagementDeeplink(deeplink, isLoggedIn, state)
            return
          }

          case 'contactus': {
            processContactUsDeeplink(deeplink, isLoggedIn, state)
            return
          }
        }
      }
    }
  } catch (e) {
    void logErrorAndShowException(e as Error, `Error executing deeplink`)
  }
}

export const navigateToZendeskIfNecessary = (): boolean => {
  try {
    const contactus = getDeeplink('contactus')
    if (contactus) {
      void goToContactUsScreen()
      void clearDeeplink(contactus)
      return true
    }
  } catch (e) {
    void logErrorAndShowException(e as Error, unableToProcessDeepLinkMessage)
  }

  return false
}

export const navigateToReferralIfNecessary = (): boolean => {
  try {
    const referral = getDeeplink('referral')
    if (referral) {
      void processReferral(referral.params.referring_user_id, referral.params.referral_code ?? '')
      void clearDeeplink(referral)
      return true
    }
  } catch (e) {
    void logErrorAndShowException(e as Error, unableToProcessDeepLinkMessage)
  }

  return false
}

export const navigateToCVVIfNecessary = (): boolean => {
  const cvv = getDeeplink('cvv')
  if (cvv) {
    goToActivatePhysicalCardScreen()
    void clearDeeplink(cvv)
    return true
  }

  return false
}

export const navigateToLoanHistoryIfNecessary = (): boolean => {
  const lh = getDeeplink('loan_documents')
  if (lh) {
    goToLoanHistoryScreen()
    void clearDeeplink(lh)
    return true
  }

  return false
}

export const navigateToCardLandingIfNecessary = (): boolean => {
  const cl = getDeeplink('card_landing')
  if (cl) {
    goToCardLandingScreen()
    return true
  }

  return false
}

export const navigateToAccountRecoveryIfNecessary = (): boolean => {
  const tp = getDeeplink('account_recovery')
  if (tp) {
    goToTempPasswordScreen()
    return true
  }

  return false
}

export const navigateToAccountManagementIfNecessary = (): boolean => {
  const am = getDeeplink('account_management')
  if (am) {
    goToAccountManagementScreen()
    return true
  }

  return false
}

export const navigateToDeeplinkIfNecessary = (): boolean => {
  return (
    navigateToReferralIfNecessary() ||
    navigateToCVVIfNecessary() ||
    navigateToLoanHistoryIfNecessary() ||
    navigateToCardLandingIfNecessary() ||
    navigateToAccountRecoveryIfNecessary() ||
    navigateToAccountManagementIfNecessary() ||
    navigateToZendeskIfNecessary()
  )
}

type DeeplinkSubscriber = React.Dispatch<
  React.SetStateAction<Partial<Record<DeeplinkType, Deeplink>>>
>
let observers: DeeplinkSubscriber[] = []

const SubscribeToCache = (subscriber: DeeplinkSubscriber): void => {
  observers.push(subscriber)
}

const UnsubscribeToCache = (unsubscriber: DeeplinkSubscriber): void => {
  observers = observers.filter((observer) => observer !== unsubscriber)
}

const UpdateCacheObservers = (value: DeeplinkCache): void => {
  observers.forEach((update) => update({...value}))
}

export const useDeeplinks = (): Partial<Record<DeeplinkType, Deeplink>> => {
  const [hookCache, setHookCache] = useState<DeeplinkCache>(cachedDeeplinks)

  useEffect(() => {
    SubscribeToCache(setHookCache)

    return () => {
      UnsubscribeToCache(setHookCache)
    }
  }, [])

  return hookCache
}
